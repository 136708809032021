import { pxToRem } from './pxToRem';

//TO DO: remove unused font style
export const textStyles = {
  headlineHero: {
    fontSize: pxToRem(40),
    lineHeight: pxToRem(56),
    fontWeight: '600',
  },
  headlineHeroSmall: {
    fontSize: pxToRem(28),
    lineHeight: pxToRem(40),
    fontWeight: '600',
  },
  headlineLarge: {
    fontSize: pxToRem(34),
    lineHeight: pxToRem(46.31),
    fontWeight: '600',
  },
  headlineMedium: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: '600',
  },
  headlineSmall: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(28),
    fontWeight: '600',
  },
  subtitleLarge: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    letterSpacing: pxToRem(0.1),
    fontWeight: '600',
  },
  subtitleSmall: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    fontWeight: '600',
    letterSpacing: pxToRem(0.25),
  },
  subtitleSmallRegular: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    fontWeight: '400',
    letterSpacing: pxToRem(0.25),
  },
  bodyLargestRegular: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: '600',
  },
  bodyLargeRegular: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: '400',
  },
  bodyLargeSemiBold: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: '600',
  },
  bodySmallRegular: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: '400',
  },
  bodySmallMedium: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: '500',
  },
  bodySmallSemibold: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: '600',
  },
  buttonMedium: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    fontWeight: '600',
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: '400',
  },
};
