import { createContext, useState, useContext, useEffect, ReactNode } from 'react';

import useHylideBridge from '../../hooks/useHylideBridge';

type UserToken = string | null; // Qi Services token
type AuthCode = string | null; // Super Qi auth code

// Create a context to hold the authcode state
const AuthCodeContext = createContext<{
  userToken: UserToken;
  authCode: AuthCode;
  status: 'success' | 'loading' | 'error';
  showWelcome?: boolean;
}>({
  userToken: null,
  authCode: null,
  status: 'loading',
  showWelcome: false,
});

// Create a provider component to wrap your application
export const AuthCodeProvider = ({ children }: { children: ReactNode }) => {
  const hylideBridge = useHylideBridge();

  const [userToken, setUserToken] = useState<UserToken>(null);
  const [authCode, setAuthCode] = useState<AuthCode>(null);
  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  const [status, setStatus] = useState<'success' | 'loading' | 'error'>('loading');

  // Get user token from Qi Services

  useEffect(() => {
    const token = window.qi.user?.mobile.token;
    setUserToken(token as string);
  }, []);
  useEffect(() => {
    if (import.meta.env.VITE_MINI_APP_PLATFORM === 'SUPER_QI') {
      try {
        hylideBridge?.getAuthCode({
          scopes: ['USER_CONTACTINFO', 'auth_user'],
          success: async ({ authCode }: { authCode: Required<AuthCode> }) => {
            setAuthCode(authCode);
            setStatus('success');
          },
          fail: () => {
            return setAuthCode(null), setStatus('error'), setShowWelcome(true);
          },
        });
      } catch (error) {
        setAuthCode(null);

        if (userToken === null) {
          setStatus('error');
        } else {
          setStatus('success');
        }
      }
    } else {
      setAuthCode(null);
      setUserToken(null);
    }
  }, [hylideBridge, userToken]);

  return (
    <AuthCodeContext.Provider
      value={{
        userToken,
        authCode,
        status,
        showWelcome,
      }}
    >
      {children}
    </AuthCodeContext.Provider>
  );
};

// Custom hook to access authcode anywhere in the app
export const useAuthCode = () => useContext(AuthCodeContext);
