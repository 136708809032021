import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';
import { OpenQiCardInstructionsContent } from './OpenQiCardInstructionsContent/OpenQiCardInstructionsContent';

interface ModalOpenQiCardInstructionsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalOpenQiCardInstructions = ({
  isOpen,
  onClose,
}: ModalOpenQiCardInstructionsProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent textAlign='right' minW={{ base: '100%', md: '820px' }}>
        <ModalCloseButton
          size={{ base: 'sm', md: 'lg' }}
          top={{ base: '20px', md: '35px' }}
          left={{ base: '20px', md: '30px' }}
        />
        <OpenQiCardInstructionsContent />
      </ModalContent>
    </Modal>
  );
};
