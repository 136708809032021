import { OrderedList } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { WithChildren } from 'types/component';

const StyledList = styled(OrderedList)<{ direction: string }>`
  direction: ${(props) => props.direction};

  & li {
    text-align: ${(props) => (props.direction === 'rtl' ? 'right' : 'left')};
    word-break: ${(props) => (props.direction === 'rtl' ? 'break-word' : 'normal')};
  }
`;

export const LocalizedList = ({ children }: WithChildren) => {
  return <StyledList direction={'rtl'}>{children}</StyledList>;
};
