import { useState, useEffect } from 'react';
import { HylideBridge } from 'api/HylideBridgeTypes';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';

const useHylideBridge = (): HylideBridge | null => {
  const [hylideBridge, setHylideBridge] = useState<HylideBridge | null>(null);

  useEffect(() => {
    const loadHylideBridge = () => {
      const bridge = (window as any).my as HylideBridge;
      setHylideBridge(bridge);
    };

    loadHylideBridge();
  }, []);

  return hylideBridge;
};

export default useHylideBridge;
