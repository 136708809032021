import axios from 'axios';

export const axiosLogger = async (string: string, data: any) => {
  if (import.meta.env.VITE_BASE_URL !== 'https://api.digitalzone-dev.net/consumers') {
    return;
  }
  const apiUrl = 'https://in.logs.betterstack.com';

  const authToken = `Bearer ${import.meta.env.VITE_AXIOS_LOGGER_AUTH_TOKEN}`;

  const requestData = {
    title: string,
    message: data,
  };

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
  };

  try {
    const response = await axios.post(apiUrl, requestData, axiosConfig);
    console.log(response.data);
  } catch (error) {
    console.error('Error:', error);
  }
};
