import { getEventAnnouncements } from 'api/ticketgeneratorpage';
import { useQuery } from 'react-query';

export const useEventAnnouncementsQuery = (id?: number) => {
  if (!id) {
    throw Error('No ticket id found!');
  }

  const { data, isFetching } = useQuery(
    `ticket-${id}-announcements`,
    async () => await getEventAnnouncements(id),
    { refetchInterval: 20000 },
  );

  return { data, isFetching };
};
