import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { ModalLogin } from 'components/ModalLogin/ModalLogin';
import { useLanguageContext } from 'context/LanguageContext/LanguageContext';
import { useState } from 'react';
import { useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { FEATURE_FLAGS_ENABLED } from 'utils/constants';
import { toast } from 'utils/toast';

export const DesktopNavbar = () => {
  const { language, selectLanguage } = useLanguageContext();

  const handleSelectLanguage = (value: string | string[]) => {
    selectLanguage(value as string);
  };
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();

  const [loginModalVisible, setloginModalVisible] = useState(false);

  const handleSignout = () => {
    signOut();
    toast({
      status: 'success',
      description: 'You have been signed out!',
    });
  };

  return (
    <>
      {loginModalVisible && (
        <ModalLogin
          onClose={() => {
            setloginModalVisible(false);
          }}
          isOpen={loginModalVisible}
        />
      )}
      {isAuthenticated() ? (
        <Button onClick={handleSignout} variant='brandMedium'>
          <FormattedMessage id='navbar.signout' defaultMessage='Sign out' />
        </Button>
      ) : (
        <Button
          onClick={() => {
            setloginModalVisible(true);
          }}
          variant='brandMedium'
        >
          <FormattedMessage id='navbar.signin' defaultMessage='Sign in' />
        </Button>
      )}

      {FEATURE_FLAGS_ENABLED ? (
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                variant='ghostWhiteNoPaddingMedium'
                isActive={isOpen}
                as={Button}
                rightIcon={<ArrowDown />}
              >
                {language === 'en' ? (
                  <FormattedMessage id='navbar.english' defaultMessage={'English'} />
                ) : language === 'ar' ? (
                  <FormattedMessage id='navbar.arabic' defaultMessage={'Arabic'} />
                ) : null}
              </MenuButton>
              <MenuList minW={145}>
                <MenuOptionGroup
                  defaultValue={language}
                  type='radio'
                  textAlign='end'
                  value={language}
                  onChange={handleSelectLanguage}
                >
                  <MenuItemOption
                    px={4}
                    py={2}
                    color='dark.D900'
                    _focus={{ background: 'green.G0', color: 'green.G500' }}
                    value='en'
                    textAlign='end'
                  >
                    <FormattedMessage id='navbar.english' defaultMessage={'English'} />
                  </MenuItemOption>
                  <MenuItemOption
                    px={4}
                    py={2}
                    color='dark.D900'
                    _focus={{ background: 'green.G0', color: 'green.G500' }}
                    value='ar'
                    borderTop='1px solid #DEE1E8'
                    textAlign='end'
                  >
                    <FormattedMessage id='navbar.arabic' defaultMessage={'Arabic'} />
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </>
          )}
        </Menu>
      ) : null}
      <Menu>
        <Link to={ROUTES.concerts} aria-label='Go to concerts page'>
          <MenuButton as={Button} variant='ghostWhiteNoPaddingMedium' pl={3} mr={2}>
            <FormattedMessage id='navbar.concert' defaultMessage='Concerts' />
          </MenuButton>
        </Link>
        <Link to={ROUTES.workshops} aria-label='Go to workshops page'>
          <MenuButton as={Button} variant='ghostWhiteNoPaddingMedium' pl={3} mr={2}>
            <FormattedMessage id='navbar.workshops' defaultMessage='Workshops' />
          </MenuButton>
        </Link>
        <Link to={ROUTES.matches} aria-label='Go to matches page'>
          <MenuButton as={Button} variant='ghostWhiteNoPaddingMedium' pl={3} mr={2}>
            <FormattedMessage id='navbar.matches' defaultMessage='Matches' />
          </MenuButton>
        </Link>
        <Link to={ROUTES.aboutUs} aria-label='Go to about us page'>
          <MenuButton as={Button} variant='ghostWhiteNoPaddingMedium' pl={3} mr={2}>
            <FormattedMessage id='navbar.about' defaultMessage='About' />
          </MenuButton>
        </Link>
        {isAuthenticated() && (
          <Link to={ROUTES.profile} aria-label='Go to about us page'>
            <MenuButton as={Button} variant='ghostWhiteNoPaddingMedium' pl={3} mr={2}>
              <FormattedMessage id='footer.profile' defaultMessage={'My Profile'} />
            </MenuButton>
          </Link>
        )}
      </Menu>
    </>
  );
};
