import {
  Box,
  Flex,
  // HStack,
  // Image,
  // Table,
  // TableContainer,
  // Tbody,
  // Td,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { LoaderOverlay } from 'components/LoaderOverlay/LoaderOverlay';
import { FormattedMessage } from 'react-intl';
import { useOrderHistory } from './hooks/useOrderHIstory';
import { HistoryCard } from 'components/HistoryCard/HistoryCard';
export const OrderHistory = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useOrderHistory();
  const screenWidth = window.innerWidth;
  const mobileContainerWidth = screenWidth - 32;
  const desktopContainerWidth = 1100 - 265;
  const bigDesktopContainerWidth = 1280 - 265;

  if (isLoading && !data) {
    return <LoaderOverlay />;
  }

  return (
    <Box
      paddingBottom='16px'
      textAlign='right'
      // width={navVariant === 'mobile' ? mobileContainerWidth + 'px' : desktopContainerWidth + 'px'}
      width={{
        base: `${mobileContainerWidth}px`,
        lg: `${desktopContainerWidth}px`,
        xl: `${bigDesktopContainerWidth}px`,
      }}
    >
      <Text variant='bodyLargestRegular'>
        <FormattedMessage id='history.heading' defaultMessage={'Your purchases'} />
      </Text>
      <Text mt={'12px'} textStyle='bodySmallRegular'>
        <FormattedMessage
          id='history.caption'
          defaultMessage={'Below you can see details of all your ticket purchases'}
        />
      </Text>
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'center' : 'start'}
        justify={'center'}
      >
        {data?.map((data) => (
          <HistoryCard
            item={data.item}
            event={{ id: data.event.id, eventType: data.event.eventType }}
            orderNumber={data.orderNumber}
            teamA={data.item.teamA}
            teamB={data.item.teamB}
            time={data.item.time}
          />
        ))}
      </Flex>
    </Box>
  );
};
