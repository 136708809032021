export enum Genders {
  Male = 'Male',
  Female = 'Female',
}

export enum Interests {
  Football = 'Football',
  Music = 'Music',
  Reading = 'Reading',
  Calligraphy = 'Calligraphy',
  Cooking = 'Cooking',
  Games = 'Games',
  Traditional_Crafts = 'Traditional_Crafts',
  Outdoor_Activities = 'Outdoor_Activities',
}
