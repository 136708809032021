import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/routes';

interface TotalProps {
  totalAmount: number;
  currency: string;
}

export const Total = ({ totalAmount, currency }: TotalProps) => {
  const navigate = useNavigate();

  return (
    <VStack
      spacing='16px'
      align='stretch'
      bg='white'
      p='16px 24px'
      boxShadow='0px 0px 50px rgba(167, 167, 167, 0.16)'
      justifySelf='end'
      position='sticky'
      bottom={0}
    >
      <HStack justify='space-between' align='center'>
        <Text variant='bodyLargestRegular'>
          {currency} {new Intl.NumberFormat().format(totalAmount)}
        </Text>
        <Text variant='bodyLargestRegular'>
          <FormattedMessage id='eventpage.total.total' defaultMessage='Total' />
        </Text>
      </HStack>
      {/* TODO add context of selected ticket, prices and event id */}
      <Button onClick={() => navigate('/' + ROUTES.checkout)} variant='primaryLarge'>
        <FormattedMessage id='eventpage.total.gotocheckout' defaultMessage='Go to checkout' />
      </Button>
    </VStack>
  );
};
