import { Image, Text, Button, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import exclamationSVG from 'assets/exclamation-mark-red.svg';

export const UnknownErrorContent = () => {
  return (
    <Flex flexDirection='column' px={{ base: '16px', md: '40px' }} py='40px' align='end' gap='12px'>
      <Image src={exclamationSVG} alt='red exclamation mark' />
      <Text variant='bodyLargestRegular' pb='14px'>
        <FormattedMessage id='checkout.modal.unknownError' defaultMessage={'Unknown error'} />
      </Text>
      <Text variant='bodyLargeRegular' pb='30px'>
        <FormattedMessage
          id='checkout.modal.unknownErrorDescription'
          defaultMessage={'An error occurred while booking ticket for the upcoming match'}
        />
      </Text>
      <Button variant='primaryLarge' width='100%'>
        <FormattedMessage id='checkout.modal.backToEvent' defaultMessage={'Back to event page'} />
      </Button>
    </Flex>
  );
};
