import { Flex, IconButton, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import CheckoutPNG from 'assets/checkout.webp';
import { ReactComponent as Chevron } from 'assets/chevron.svg';
import MatchPNG from 'assets/match.webp';
import SeatsPNG from 'assets/ticket-seats.webp';
import Carousel from 'nuka-carousel';
import { FormattedMessage } from 'react-intl';
import { colors } from 'styles/colors';
import { iconStyles } from './InfoSection.styles';

const CheckoutInfo = () => (
  <VStack justify='space-between' align='center'>
    <Image src={CheckoutPNG} alt='a close up of a ticket' />
    <VStack textAlign='center' maxWidth='320px'>
      <Text as='h3' variant='bodyLargeSemiBold'>
        <FormattedMessage id='homepage.tickethowto.chooseMatch' defaultMessage='Choose the match' />
      </Text>
      <Text variant='bodySmallRegular'>
        <FormattedMessage
          id='homepage.tickethowto.chooseMatchInfo'
          defaultMessage='Decide on the game you want to attend and select the date that works best for you.'
        />
      </Text>
    </VStack>
  </VStack>
);

const TicketTypeInfo = () => (
  <VStack justify='space-between' align='center'>
    <Image src={SeatsPNG} alt='a graphic representing a ticket' />
    <VStack textAlign='center' maxWidth='320px'>
      <Text as='h3' variant='bodyLargeSemiBold'>
        <FormattedMessage
          id='homepage.tickethowto.ticketType'
          defaultMessage='Select your ticket type'
        />
      </Text>
      <Text variant='bodySmallRegular'>
        <FormattedMessage
          id='homepage.tickethowto.ticketTypeInfo'
          defaultMessage='Choose your seat and the type of ticket you want. Make sure to select the correct quantity of tickets needed.'
        />
      </Text>
    </VStack>
  </VStack>
);

const MatchInfo = () => (
  <VStack justify='space-between' align='center'>
    <Image src={MatchPNG} alt='a graphic representing a match' />
    <VStack textAlign='center' maxWidth='320px'>
      <Text as='h3' variant='bodyLargeSemiBold'>
        <FormattedMessage id='homepage.tickethowto.checkout' defaultMessage='Checkout' />
      </Text>
      <Text variant='bodySmallRegular'>
        <FormattedMessage
          id='homepage.tickethowto.checkoutInfo'
          defaultMessage='Fill info and payment details to complete purchase. Your digital tickets will be
              delivered to you via email or SMS'
        />
      </Text>
    </VStack>
  </VStack>
);

export const InfoSectionDesktop = () => {
  return (
    <SimpleGrid minChildWidth='240px' justifyItems='center' textAlign='center' mt='64px' gap='30px'>
      <CheckoutInfo />
      <TicketTypeInfo />
      <MatchInfo />
    </SimpleGrid>
  );
};

export const InfoSectionMobile = () => {
  return (
    <Flex role='region' direction='column' justify='center' mt='64px' position='relative'>
      <Carousel
        renderCenterRightControls={({ nextSlide }) => (
          <IconButton
            borderRadius='50%'
            backgroundColor={colors.offwhite}
            position='absolute'
            right={0}
            className={iconStyles}
            aria-label='Ticket buying info next'
            icon={<Chevron />}
            onClick={nextSlide}
          />
        )}
        renderCenterLeftControls={({ previousSlide }) => (
          <IconButton
            position='absolute'
            borderRadius='50%'
            backgroundColor={colors.offwhite}
            left={0}
            aria-label='Ticket buying info previous'
            icon={<Chevron style={{ transform: 'rotate(180deg)' }} />}
            onClick={previousSlide}
            className={iconStyles}
          />
        )}
        renderBottomCenterControls={null}
      >
        <CheckoutInfo />
        <TicketTypeInfo />
        <MatchInfo />
      </Carousel>
    </Flex>
  );
};
