import {
  Badge,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import checkoutTicket from 'assets/checkout-ticket.webp';
import flag from 'assets/flag.webp';
import { useCartContext } from 'context/CartContext/CartContext';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { DIALING_CODE_IRAQ, INVALID_PHONE_NUMBER_REGEX, TWO_WORDS_MIN } from 'utils/constants';
import * as yup from 'yup';

export type FormValues = yup.InferType<typeof schema>;

const schema = yup.object().shape({
  ticketHolders: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required('checkout.fieldIsRequired')
          .matches(TWO_WORDS_MIN, 'checkout.invalidName'),
        phoneNumber: yup
          .string()
          .required('checkout.fieldIsRequired')
          .matches(INVALID_PHONE_NUMBER_REGEX),
      }),
    )
    .required(),
});

interface TicketHolderFormProps {
  onSubmit: (data: FormValues) => void;
}

export const TicketHolderForm = ({ onSubmit }: TicketHolderFormProps) => {
  const dialingCode = localStorage.getItem('dialingCode') || DIALING_CODE_IRAQ;
  const { formatMessage } = useIntl();
  const { tickets } = useCartContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  return (
    <form id='ticket-holder-hook-form' onSubmit={handleSubmit(onSubmit)}>
      {tickets.map((ticket, index) => (
        <Flex
          bg='white'
          p={{ base: '4px', md: '24px' }}
          gap={{ base: '0px', md: '24px' }}
          borderRadius='16px'
          borderColor='white'
          borderWidth='1px'
          textAlign='end'
          key={index}
          mb='16px'
        >
          <VStack flex='1' align='center'>
            <HStack>
              <Badge
                variant='primary'
                textStyle='bodySmallSemibold'
                alignItems='center'
                px={2}
                textTransform='capitalize'
              >
                <FormattedMessage
                  id='checkout.sector'
                  description='Greeting to welcome the user to the app'
                  defaultMessage='Sector {sectorName}'
                  values={{
                    sectorName: ticket.sector,
                  }}
                />
              </Badge>
              <Badge
                variant='primary'
                textStyle='bodySmallSemibold'
                alignItems='center'
                px={2}
                textTransform='capitalize'
              >
                {ticket.category}
              </Badge>

              <Text textStyle='subtitleLarge' fontSize={{ base: 'sm', md: 'lg' }}>
                <FormattedMessage
                  defaultMessage={'Ticket {ticketNo}'}
                  id='checkout.ticket.ticketNo'
                  values={{
                    ticketNo: index + 1,
                  }}
                />
              </Text>
            </HStack>
            <Flex flexDirection={{ base: 'column', md: 'row' }} gap='24px' width='100%'>
              <VStack align='end' flex='1'>
                <Text textStyle='bodySmallMedium'>
                  <FormattedMessage id='checkout.phoneNumber' defaultMessage={'Phone number'} />
                </Text>

                <InputGroup>
                  <Controller
                    name={`ticketHolders.${index}.phoneNumber` as const}
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <>
                        <InputLeftElement pointerEvents='none' mx={1}>
                          <Image src={flag} boxSize={6} alt='image of Iraqi flag' />
                        </InputLeftElement>
                        <InputLeftElement
                          pointerEvents='none'
                          borderLeft='1px solid dark.D150'
                          margin='8px 0 0 45px'
                          height={6}
                          paddingLeft='15px'
                        >
                          {dialingCode}
                        </InputLeftElement>
                        <Input
                          onWheel={(e: any) => e.target.blur()}
                          placeholder='7XXXXXXXX'
                          aria-label='phoneNumber'
                          paddingLeft='95px'
                          type='tel'
                          id='phoneNumberInput'
                          borderRadius={12}
                          color='dark.D900'
                          focusBorderColor={
                            errors?.ticketHolders?.[index]?.phoneNumber ? 'red' : 'green.G500'
                          }
                          aria-invalid={Boolean(errors?.ticketHolders?.[index]?.phoneNumber)}
                          aria-errormessage={
                            !errors?.ticketHolders?.[index]?.phoneNumber
                              ? undefined
                              : 'phoneNumberError'
                          }
                          {...field}
                        />
                      </>
                    )}
                  />
                </InputGroup>
                {errors?.ticketHolders?.[index]?.phoneNumber && (
                  <Text textStyle='bodySmallMedium' color='red' mt={2} id='phoneNumberError'>
                    {formatMessage({
                      id: 'loginModal.invalid',
                    })}
                  </Text>
                )}
              </VStack>
              <VStack flex='1' align='end'>
                <Text textStyle='bodySmallMedium'>
                  <FormattedMessage id='checkout.name' defaultMessage={'Name and Father’s name'} />
                </Text>
                <Controller
                  name={`ticketHolders.${index}.name` as const}
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Input
                      aria-label='name'
                      maxLength={50}
                      borderRadius={12}
                      focusBorderColor={errors?.ticketHolders?.[index]?.name ? 'red' : 'green.G500'}
                      aria-invalid={Boolean(errors?.ticketHolders?.[index]?.name)}
                      aria-errormessage={
                        !errors?.ticketHolders?.[index]?.name ? undefined : 'nameError'
                      }
                      {...field}
                    />
                  )}
                />
                {errors?.ticketHolders?.[index]?.name && (
                  <Text textStyle='bodySmallMedium' color='red' mt={2} id='phoneNumberError'>
                    {formatMessage({
                      id: errors.ticketHolders?.[index]?.name?.message,
                      defaultMessage: 'Name is required',
                    })}
                  </Text>
                )}
              </VStack>
            </Flex>
          </VStack>

          <Center display={{ base: 'none', md: 'flex' }}>
            <Divider orientation='vertical' colorScheme='green' />
          </Center>

          <Image
            src={checkoutTicket}
            alt='representation of the ticket'
            display={{ base: 'none', md: 'flex' }}
          />
        </Flex>
      ))}
    </form>
  );
};
