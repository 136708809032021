import { Box, Flex, Image } from '@chakra-ui/react';

import Qi from '../../assets/QI-new-logo.svg';
import TZ from '../../assets/ticket-zone-logo-only.svg.svg';

interface Props {
  autoGap?: boolean;
}

export const Sponsors = ({ autoGap }: Props) => {
  return (
    <Flex
      alignSelf='center'
      justifyContent={autoGap ? 'end' : 'end'}
      columnGap={autoGap ? '0px' : { base: '0', md: '0' }}
    >
      {/* TO DO: add proper sponsor names to alt text */}
      <Box display={'flex'} gap={'52.05px'} alignSelf={'start'}>
        <Image alt='sponsors logo' src={TZ} width={'16'} height={'16'} />
        <Image alt='sponsors logo' src={Qi} width={'16'} height={'16'} />
      </Box>
    </Flex>
  );
};
