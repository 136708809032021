import { useMediaQuery } from '@chakra-ui/react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { MatchResponse } from 'api/responseTypes';
import { useCartContext } from 'context/CartContext/CartContext';
import { useHoldTokenSessionTimerContext } from 'context/HoldTokenSessionTimerContext/HoldTokenSessionTimerContext';
import { useLanguageContext } from 'context/LanguageContext/LanguageContext';
import { useEffect, useState } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { ChartSeats, GASeatsPartial, SessionInitialized } from 'types/seats';
import { FEATURE_FLAGS_ENABLED } from 'utils/constants';

type StadiumProps = Pick<MatchResponse, 'pricing'> & {
  event: string;
  currency: string;
  onOpenModal: () => void;
};

export const Stadium = ({ pricing, event, currency, onOpenModal }: StadiumProps) => {
  const { clearCart, updateTickets, setHoldToken } = useCartContext();
  const isAuthenticated = useIsAuthenticated();
  const [mode, setMode] = useState<'normal' | 'static'>('static');
  const [isMobileWidth] = useMediaQuery('(max-width: 400px)');
  const { resetTimer } = useHoldTokenSessionTimerContext();
  const { language } = useLanguageContext();

  useEffect(() => {
    if (isAuthenticated()) {
      setMode('normal');
    } else {
      setMode('static');
    }
  }, [isAuthenticated]);

  const handleSectorInteraction = (ga: GASeatsPartial) => {
    updateTickets(ga);
  };

  return (
    <SeatsioSeatingChart
      width='100vw'
      language={language === 'en' && FEATURE_FLAGS_ENABLED ? 'en' : 'ar'}
      workspaceKey={import.meta.env.VITE_APP_WORKSPACE_KEY}
      event={event}
      region='eu'
      maxSelectedObjects={4}
      pricing={pricing}
      session='start'
      showFullScreenButton={isMobileWidth}
      onChartRendered={(chart: ChartSeats) => {
        clearCart();
        setHoldToken(chart.holdToken);
      }}
      onSessionInitialized={(session: SessionInitialized) => {
        resetTimer(session.expiresInSeconds);
      }}
      onHoldTokenExpired={clearCart}
      onObjectSelected={handleSectorInteraction}
      onObjectDeselected={handleSectorInteraction}
      priceFormatter={(price: string) =>
        currency + ' ' + new Intl.NumberFormat().format(Number(price))
      }
      mode={mode}
      onObjectClicked={() => {
        onOpenModal();
      }}
    />
  );
};
