import { UnorderedList } from '@chakra-ui/react';
import FooterNavigationListItem from './FooterNavigationListItem';

const FooterNavigation = () => {
  return (
    <UnorderedList
      display='flex'
      justifyContent='space-around'
      flexDirection='row-reverse'
      w='md'
      mx='8'
      listStyleType='none'
    >
      <FooterNavigationListItem
        id='footer.company'
        defaultMessage={'Company'}
        link={'/about-us'}
        linkId='footer.aboutUs'
      />

      <FooterNavigationListItem
        id='footer.support'
        defaultMessage={'Support'}
        link='https://wa.me/009647807350000'
        linkId='footer.supportCenter'
      />
    </UnorderedList>
  );
};

export default FooterNavigation;
