import {
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as facebookSVG } from 'assets/facebook-circled.svg';
import { ReactComponent as Hamburger } from 'assets/hamburger.svg';
import { ReactComponent as instagramSVG } from 'assets/instagram.svg';
import { ReactComponent as linkedInSVG } from 'assets/linkedIn.svg';
import logo from '../../../assets/logo-new.svg';
import { ReactComponent as youtubeSVG } from 'assets/youtube.svg';
import { ModalLogin } from 'components/ModalLogin/ModalLogin';
import { useLanguageContext } from 'context/LanguageContext/LanguageContext';
import { useState } from 'react';
import { useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'router/routes';
import { FB_URL, FEATURE_FLAGS_ENABLED, INSTAGRAM_URL, LINKEDIN_URL } from 'utils/constants';
import { toast } from 'utils/toast';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';
import { Link } from 'react-router-dom';
import { useAuthCode } from 'context/AuthCodeContext/AuthCodeContext';

export const MobileNavbar = () => {
  const { userToken, authCode } = useAuthCode();
  const { selectLanguage } = useLanguageContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();

  const [loginModalVisible, setloginModalVisible] = useState(false);

  const handleSignout = () => {
    signOut();
    toast({
      status: 'info',
      description: 'You have been signed out!',
    });
  };

  return (
    <>
      <CloseButton onClick={onOpen} size='lg' mt='-10px' ml='-10px'>
        <Hamburger />
      </CloseButton>

      <Drawer placement='left' onClose={onClose} isOpen={isOpen} size='full'>
        <DrawerOverlay />
        <DrawerContent bg='green.G900' px='16px'>
          <DrawerHeader justifyContent='end' px='0'>
            <Flex justifyContent='end'>
              <Image
                src={logo}
                w={{ base: '125px', md: '120px' }}
                h={{ base: '32px', md: '62px' }}
                alt='ticket zone logo'
              />
            </Flex>
            <DrawerCloseButton size='lg' color='white' pt='0px' />
          </DrawerHeader>
          <DrawerBody textAlign='end' px='0'>
            {userToken === null && authCode === null && FEATURE_FLAGS_ENABLED ? (
              <Flex justifyContent='end' alignItems='baseline'>
                <Button
                  px='0'
                  color='white'
                  variant='ghostWhiteNoPaddingMedium'
                  onClick={() => selectLanguage('ar')}
                >
                  <FormattedMessage id='navbar.ar' defaultMessage={'AR'} />
                </Button>
                <Text color='green.G50'>|</Text>
                <Button
                  px='0'
                  color='white'
                  variant='ghostWhiteNoPaddingMedium'
                  onClick={() => selectLanguage('en')}
                >
                  <FormattedMessage id='navbar.en' defaultMessage={'EN'} />
                </Button>
              </Flex>
            ) : null}
            <Divider borderColor={{ base: 'white', md: '#078977' }} mt='14px' mb='24px' />
            <Flex justifyContent='flex-end' alignItems='flex-end' flexDirection='column' gap={4}>
              {loginModalVisible && (
                <ModalLogin
                  onClose={() => {
                    setloginModalVisible(false);
                  }}
                  isOpen={loginModalVisible}
                />
              )}
              {userToken === null && authCode === null && isAuthenticated() && (
                <Button onClick={handleSignout} variant='brandMedium'>
                  <FormattedMessage id='navbar.signout' defaultMessage='Sign out' />
                </Button>
              )}
              {userToken === null && authCode === null && !isAuthenticated() && (
                <Button
                  type='button'
                  onClick={() => {
                    setloginModalVisible(true);
                  }}
                  variant='brandMedium'
                >
                  <FormattedMessage id='navbar.signin' defaultMessage='Sign in' />
                </Button>
              )}
              {userToken === null && authCode === null && isAuthenticated() && (
                <Link
                  to={ROUTES.profile}
                  style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                  onClick={onClose}
                >
                  <FormattedMessage id='footer.profile' defaultMessage={'My Profile'} />
                </Link>
              )}
              <Link
                to={ROUTES.aboutUs}
                style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                onClick={onClose}
              >
                <FormattedMessage id='footer.about' defaultMessage={'About Ticket Zone'} />
              </Link>
              <Link
                to={ROUTES.concerts}
                style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                onClick={onClose}
              >
                <FormattedMessage id='navbar.concert' defaultMessage={'Concerts'} />
              </Link>
              <Link
                to={ROUTES.workshops}
                style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                onClick={onClose}
              >
                <FormattedMessage id='navbar.workshops' defaultMessage={'Workshops'} />
              </Link>
              <Link
                to={ROUTES.matches}
                style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                onClick={onClose}
              >
                <FormattedMessage id='navbar.matches' defaultMessage={'Matches'} />
              </Link>
              <Link
                to={ROUTES.termsOfUse}
                style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                onClick={onClose}
              >
                <FormattedMessage id='footer.terms' defaultMessage={'Terms of Use'} />
              </Link>
              <Link
                to={ROUTES.privacyPolicy}
                style={{ color: '#b3dbd5', fontWeight: 'bold' }}
                onClick={onClose}
              >
                <FormattedMessage id='footer.privacy' defaultMessage={'Privacy policy'} />
              </Link>
              {import.meta.env.VITE_APP_BASE_API ===
                'https://api.digitalzone-dev.net/consumers' && (
                <Link
                  to={ROUTES.test}
                  onClick={onClose}
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: 'yellow',
                    padding: '5px',
                    borderRadius: '5px',
                    width: '60px',
                    textAlign: 'center',
                  }}
                >
                  Test
                </Link>
              )}
            </Flex>
          </DrawerBody>

          <DrawerFooter justifyContent='space-between' flexDirection='column' px='0'>
            <Divider borderColor={{ base: 'white', md: '#078977' }} mb='19px' />

            <Flex gap={5} alignSelf='flex-end'>
              <Link aria-label='Facebook' to={FB_URL} target='_blank' rel='noopener noreferrer'>
                <Icon as={facebookSVG} boxSize={6} color='green.G50' />
              </Link>
              <Link
                aria-label='LinkedIn'
                to={LINKEDIN_URL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon as={linkedInSVG} boxSize={6} color='green.G50' />
              </Link>
              <Link
                aria-label='You Tube'
                to='https://www.youtube.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon as={youtubeSVG} boxSize={6} color='green.G50' />
              </Link>
              <Link
                aria-label='Instagram'
                to={INSTAGRAM_URL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon as={instagramSVG} boxSize={6} color='green.G50' />
              </Link>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
