import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { UnknownErrorContent } from './UnknownErrorContent/UnknownErrorContent';

interface ModalUnknownErrorProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalUnknownError = ({ isOpen, onClose }: ModalUnknownErrorProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent textAlign='right' minW={{ base: '100%', md: '440px' }}>
        <UnknownErrorContent />
      </ModalContent>
    </Modal>
  );
};
