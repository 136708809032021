import { getUpcomingMatches } from 'api/homepage';
import { useQuery } from 'react-query';

export const useUpcomingMatchesQuery = () => {
  const { isLoading, data } = useQuery('upcomingMatches', getUpcomingMatches);
  return {
    isLoading,
    data,
  };
};
