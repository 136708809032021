import {
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Image,
  Flex,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import loginBanner from 'assets/login-banner.webp';
import flag from 'assets/flag.webp';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { apiAuthSignup } from 'api/auth';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import { DIALING_CODE_IRAQ, INVALID_PHONE_NUMBER_REGEX } from 'utils/constants';

const schema = yup
  .object({
    phone: yup
      .string()
      .matches(INVALID_PHONE_NUMBER_REGEX, 'loginModal.invalid')
      .required('loginModal.invalid'),
  })
  .required();

type FormValues = yup.InferType<typeof schema>;

interface PhoneNumberContentProps {
  onChangeCredential: (value: { phone: string; session: string }) => void;
}

export const PhoneNumberContent = ({ onChangeCredential }: PhoneNumberContentProps) => {
  const dialingCode = localStorage.getItem('dialingCode') || DIALING_CODE_IRAQ;
  const { formatMessage } = useIntl();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    const phoneNumber = dialingCode + data.phone;
    try {
      const response = await apiAuthSignup({
        phone: phoneNumber,
      });
      onChangeCredential({ phone: phoneNumber, session: response.session });
    } catch (e) {
      const error = e as AxiosError | Error;

      if (axios.isAxiosError(error) && error.response?.status === 400) {
        setError('phone', {
          type: 'manual',
          message: error.response.data.message,
        });
      }
    }
  };

  return (
    <Box as='section' aria-labelledby='phoneNumberHeader phoneNumberSubtitle'>
      <ModalHeader>
        <Text id='phoneNumberHeader' textStyle='headlineMedium' color='dark.D900'>
          <FormattedMessage id='loginModal.title' defaultMessage={'Enter phone number'} />
        </Text>

        <Text id='phoneNumberSubtitle' mt={2} textStyle='bodySmallRegular' color='dark.D500'>
          <FormattedMessage
            id='loginModal.subtitle'
            defaultMessage={'Enter your mobile phone number to log in or create an account.'}
          />
        </Text>
      </ModalHeader>

      <ModalCloseButton size={{ base: 'sm', md: 'lg' }} />
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor='phoneNumberInput'>
            <Text textStyle='bodySmallMedium' color='dark.D900' mb={1}>
              <FormattedMessage id='loginModal.phoneNumber' defaultMessage={'Phone number'} />
            </Text>
          </label>

          <InputGroup>
            <Controller
              defaultValue=''
              control={control}
              name='phone'
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputLeftElement pointerEvents='none' mx={1}>
                    <Image src={flag} boxSize={6} alt='image of Iraqi flag' />
                  </InputLeftElement>
                  <InputLeftElement
                    pointerEvents='none'
                    borderLeft='1px solid #DFE1E1'
                    margin='8px 0 0 45px'
                    height={6}
                    paddingLeft='15px'
                  >
                    {dialingCode}
                  </InputLeftElement>
                  <Input
                    placeholder='7XXXXXXXXX'
                    paddingLeft='95px'
                    type='tel'
                    id='phoneNumberInput'
                    borderRadius={12}
                    color='dark.D900'
                    focusBorderColor={error ? 'red' : 'green.G500'}
                    aria-invalid={Boolean(errors.phone)}
                    aria-errormessage={!errors.phone ? undefined : 'phoneNumberError'}
                    {...field}
                  />
                </>
              )}
            />
          </InputGroup>

          {errors.phone ? (
            errors.phone.type === 'manual' ? (
              <Text textStyle='bodySmallMedium' color='red' mt={2} id='phoneNumberError'>
                {errors.phone.message}
              </Text>
            ) : (
              <Text textStyle='bodySmallMedium' color='red' mt={2} id='phoneNumberError'>
                {formatMessage({
                  id: errors.phone.message,
                  defaultMessage: 'Invalid phone number. Must start with 7 and be 10 digits.',
                })}
              </Text>
            )
          ) : null}

          <Button
            aria-label='Submit the phone number'
            mt={8}
            minW='100%'
            variant='primaryLarge'
            type='submit'
            isLoading={isSubmitting}
          >
            <FormattedMessage id='loginModal.button' defaultMessage={'Continue'} />
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <Box position='relative'>
          <Image
            src={loginBanner}
            alt='two soccer players with arms outstretched'
            w={{ base: 'fit', md: 460 }}
            h={{ base: '167px', md: 139 }}
            objectFit={{ base: 'cover', md: 'unset' }}
            borderBottomRadius='16px'
          />
          <Flex
            position='absolute'
            top='0'
            right='0'
            width='60%'
            height={{ base: '167px', md: '100%' }}
            justifyContent='center'
            direction='column'
            px={{ base: '16px', md: '40px' }}
          >
            <Text textStyle='subtitleLarge' color='white' mb={1}>
              <FormattedMessage
                id='loginModal.bannerTitle'
                defaultMessage={'Buy tickets for your favourite matches with us'}
              />
            </Text>
            <Text textStyle='bodySmallRegular' color='white' mb={1}>
              <FormattedMessage
                id='loginModal.bannerSubtitle'
                defaultMessage={'With us, buying tickets is convenient and easy'}
              />
            </Text>
          </Flex>
        </Box>
      </ModalFooter>
    </Box>
  );
};
