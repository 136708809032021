import { Input as InputBase, InputProps as InputPropsBase, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface InputProps extends InputPropsBase {
  label?: ReactNode;
  errorMsg?: string;
  optional?: boolean;
}

export const Input = ({ label, errorMsg, optional, ...props }: InputProps) => {
  return (
    <>
      <label style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Text textStyle='bodySmallMedium'>
          {optional && (
            <>
              {'('}
              <FormattedMessage id='profile.optional' defaultMessage={'Optional'} />
              {') '}
            </>
          )}
          {label}{' '}
        </Text>
        <InputBase textAlign={'right'} {...props} />
      </label>
      {errorMsg ? (
        <Text textStyle='bodySmallMedium' color='red'>
          <FormattedMessage id={errorMsg} defaultMessage={'Field is invalid!'} />
        </Text>
      ) : null}
    </>
  );
};
