import { putProfileInfo } from 'api/profilepage';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';

export const useProfileDataMutation = () => {
  const { mutate, status, isError } = useMutation(putProfileInfo, {
    onError: (e: AxiosError | Error) => {
      console.error('Error:', e);
      if (axios.isAxiosError(e)) {
        toast({
          description: e.response?.data?.message || e.message,
          status: 'error',
        });
      }
    },
  });

  return {
    mutate,
    isError,
    status,
  };
};
