import { getHistoryOrdersInfo } from 'api/historypage';
import { useQuery } from 'react-query';

export const useOrderHistory = () => {
  const { isLoading, data } = useQuery('orders', getHistoryOrdersInfo);
  return {
    isLoading,
    data,
  };
};
