export const modalStyle = {
  overlay: {
    bg: 'rgba(0, 0, 0, 0.7)',
    backdropFilter: 'blur(5px)',
  },
  dialog: {
    borderRadius: '16px',
  },
  header: {
    pt: { base: '48px', md: '40px' },
    px: { base: '16px', md: '40px' },
  },
  body: {
    px: { base: '16px', md: '40px' },
    pb: { base: '32px', md: '40px' },
  },
  footer: {
    px: '0',
    py: '0',
  },
};
