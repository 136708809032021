import { getProfileInfo } from 'api/profilepage';
import { useQuery } from 'react-query';

export const useProfileData = () => {
  const { isLoading, data } = useQuery('profileDetails', getProfileInfo);
  return {
    isLoading,
    data,
  };
};
