import { Button, Collapse, HStack, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const TicketInstruction = () => {
  const { formatMessage } = useIntl();
  const [toggleState, setToggleState] = useState([false, false, false]);

  const toggleGroupState = (groupIndex: number) => {
    setToggleState((prevStates) =>
      prevStates.map((state, index) => (index === groupIndex ? !state : state)),
    );
  };

  const instructionsMessages = [
    {
      title: formatMessage({
        id: 'orderSummary.ticketInstruction.first',
        defaultMessage: '1. Open the link with your ticket',
      }),
      description: formatMessage({
        id: 'orderSummary.ticketInstruction.firstDescription',
        defaultMessage:
          'After a successful purchase you will recieve an SMS with a link to your ticket. The QR code will appear 6h before the match. Once it is displayed you can take a screenshot in case there is poor internet connection in the stadium.',
      }),
    },
    {
      title: formatMessage({
        id: 'orderSummary.ticketInstruction.second',
        defaultMessage: '2. Get ready for match day',
      }),
      description: formatMessage({
        id: 'orderSummary.ticketInstruction.secondDescription',
        defaultMessage:
          'On the day of the event, make sure to arrive at the stadium with plenty of time to spare. Check the event details on your ticket(s) for important information such as gate entrance, start time, and seating location.',
      }),
    },
    {
      title: formatMessage({
        id: 'orderSummary.ticketInstruction.third',
        defaultMessage: '3. Show your ticket and scan the QR code',
      }),
      description: formatMessage({
        id: 'orderSummary.ticketInstruction.thirdDescription',
        defaultMessage:
          'When you arrive at the stadium, present your ticket(s) to the ticket controller or usher. They will scan the QR code on your ticket(s) with a handheld scanner to confirm your admission. Once your ticket(s) are validated, you will be directed to your seat and ready to enjoy the event!',
      }),
    },
  ];

  return (
    <VStack spacing='16px' align='stretch' bg='white' borderRadius='16px' p='24px' textAlign='end'>
      <Text variant='bodyLargeSemiBold'>
        <FormattedMessage
          id='orderSummary.ticketInstruction.title'
          defaultMessage={'What to do with ticket'}
        />
      </Text>

      {instructionsMessages.map((instruction, index) => (
        <HStack align='start' borderRadius='16px' borderWidth='1px' p='24px'>
          <Button
            onClick={() => toggleGroupState(index)}
            variant='ghostWhiteNoPaddingMedium'
            h='26px'
          >
            {toggleState[index] ? (
              <Icon as={ArrowDown} />
            ) : (
              <Icon as={ArrowDown} transform='rotate(180deg)' />
            )}
          </Button>
          <Collapse
            startingHeight={26}
            in={toggleState[index]}
            onClick={() => toggleGroupState(index)}
            style={{ cursor: 'pointer' }}
          >
            <Heading variant='headingSmall'>{instruction.title}</Heading>
            <Text variant='bodySmallRegular' mt='8px'>
              {instruction.description}
            </Text>
          </Collapse>
        </HStack>
      ))}
    </VStack>
  );
};
