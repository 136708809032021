import { getUpcomingConcerts } from 'api/concerts';
import { useQuery } from 'react-query';

export const useUpcomingConcertsQuery = () => {
  const { isLoading, data } = useQuery('upComingConcert', getUpcomingConcerts);
  return {
    isLoading,
    data,
  };
};
