import { css } from '@emotion/css';

export const iconStyles = css({
  cursor: 'pointer',
  height: 40,
  width: 40,
  top: 'calc(50% - 20px)',
  zIndex: 1,
  boxShadow: ' 0px 8px 16px -8px #0F0F0F33',
});
