import { Flex, HStack, Image, Text } from '@chakra-ui/react';
import qiSVG from 'assets/qi-yellow-border.svg';
import mastercardSVG from 'assets/mastercard-yellow-border.svg';
import { FormattedMessage } from 'react-intl';

export const AcceptedPayment = () => {
  return (
    <HStack
      align='center'
      justify='space-between'
      borderWidth='1px 0'
      borderColor='dark.D100'
      py='24px'
      mx='24px'
    >
      <Flex justifyContent='flex-start' gap='16px'>
        <Image src={qiSVG} />
        <Image src={mastercardSVG} />
      </Flex>
      <Text variant='bodyLargeSemiBold'>
        <FormattedMessage defaultMessage='Accepted payment' id='eventpage.acceptedpayment.info' />
      </Text>
    </HStack>
  );
};
