/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridItem } from '@chakra-ui/react';
import { LoaderOverlay } from 'components/LoaderOverlay/LoaderOverlay';
import { useCartContext } from 'context/CartContext/CartContext';
import { GameInfo } from 'pages/EventPage/GameInfo/GameInfo';
import { TicketCart } from 'pages/EventPage/TicketCart/TicketCart';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stadium } from './Stadium/Stadium';
import { useEventQuery } from './hooks/useEvent';
import { useIsAuthenticated } from 'react-auth-kit';
import { ModalLogin } from 'components/ModalLogin/ModalLogin';
import { LoginInformation } from './LoginInformation/LoginInformation';

export const EventPage = () => {
  const { eventKey } = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const { data, isFetching, isError } = useEventQuery(eventKey);

  const { tickets, setEventId, setEventKey } = useCartContext();

  useEffect(() => {
    if (data && !isFetching) {
      setEventId(data.match.eventId);
      setEventKey(data.match.eventKey);
    }
  }, [data?.match.eventKey, data?.match.id]);

  const handleCloseModal = () => {
    setIsAuthModalOpen(false);
  };

  const handleOpenModal = () => {
    if (!isAuthenticated()) {
      setIsAuthModalOpen(true);
    }
  };

  if (isFetching) {
    return <LoaderOverlay />;
  }

  return !isError && data ? (
    <>
      <GameInfo {...data.match} />
      <Grid mx='auto' maxW='1440px' templateColumns={{ base: '1fr', md: '460px 1fr' }}>
        <GridItem h={{ md: '1000px' }} minH='230px'>
          {!isAuthenticated() ? (
            <LoginInformation onModalOpen={handleOpenModal} />
          ) : (
            <TicketCart currency={data.match.currency} selectedTickets={tickets} />
          )}
        </GridItem>
        <GridItem rowStart={{ base: 1, md: 'auto' }} alignSelf='start' h={{ md: '1000px' }}>
          <Stadium
            pricing={data.pricing}
            event={data.match.eventKey}
            currency={data.match.currency}
            onOpenModal={handleOpenModal}
          />
        </GridItem>
      </Grid>

      <ModalLogin isOpen={isAuthModalOpen} onClose={handleCloseModal} />
    </>
  ) : null;
};
