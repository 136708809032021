import { Image, Text, Button, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import exclamationSVG from 'assets/exclamation-mark-red.svg';
import { useCartContext } from 'context/CartContext/CartContext';
import { useNavigate } from 'react-router-dom';

export const ReservationExpiredContent = () => {
  const navigate = useNavigate();
  const { eventInfoId } = useCartContext();

  return (
    <Flex flexDirection='column' px={{ base: '16px', md: '40px' }} py='40px' align='end' gap='12px'>
      <Image src={exclamationSVG} alt='red exclamation mark' />
      <Text variant='bodyLargestRegular' pb='14px'>
        <FormattedMessage
          id='checkout.modal.reservationExpired'
          defaultMessage={'Ticket reservation expired'}
        />
      </Text>
      <Text variant='bodyLargeRegular' pb='30px'>
        <FormattedMessage
          id='checkout.modal.reservationExpiredDescription'
          defaultMessage={
            'Your ticket reservation has expired because we cannot hold the ticket any longer. If you still want to attend, please visit our website to purchase a new ticket.'
          }
        />
      </Text>
      <Button variant='primaryLarge' width='100%' onClick={() => navigate(`/event/${eventInfoId}`)}>
        <FormattedMessage id='checkout.modal.backToEvent' defaultMessage={'Back to event page'} />
      </Button>
    </Flex>
  );
};
