import { Box, Button, Divider, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { ConcertInfo } from 'api/responseTypes';
import { ReactComponent as PlaceholderTeamLogo } from 'assets/placeholder.svg';
import { useCartContext } from 'context/CartContext/CartContext';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { TicketPrice } from './TicketPrice/TicketPrice';

type ConcertTicketSummaryProps = Pick<
  ConcertInfo,
  'date' | 'time' | 'address' | 'imageLink' | 'title'
>;

export const ConcertTicketSummary = ({
  date,
  time,
  address,
  imageLink,
  title,
  isLoading,
}: ConcertTicketSummaryProps & { isLoading: boolean }) => {
  const { tickets } = useCartContext();

  return (
    <Box position='relative'>
      <Box
        bg='BG100'
        borderRadius='50%'
        position='absolute'
        w='30px'
        h='30px'
        top='120px'
        right='-15px'
      />
      <Box
        bg='BG100'
        borderRadius='50%'
        position='absolute'
        w='30px'
        h='30px'
        top='120px'
        left='-15px'
      />
      <Flex
        direction='column'
        bg='white'
        p='24px'
        gap='16px'
        borderRadius='16px'
        borderColor='white'
        borderWidth='1px'
        boxShadow='0 5px 5px rgba(0, 0, 0, 0.02)'
      >
        <HStack justifyContent='space-between' mt='6px' alignItems='start' textAlign='center'>
          <VStack dir='rtl' spacing='4px' pt='5px'>
            <Text id='team1' textStyle='bodySmallMedium'>
              {title}
            </Text>
            <Divider />
            <Text textStyle='bodySmallSemibold'>
              {time} {date}
            </Text>
            <Text textStyle='bodySmallMedium'>{address}</Text>
          </VStack>

          <VStack>
            <Image
              src={imageLink}
              boxSize='100px'
              borderRadius='10%'
              height='auto'
              width='100px'
              fallback={<PlaceholderTeamLogo />}
            />
          </VStack>
        </HStack>

        <Divider variant='dashed' borderColor='dark.D100' borderWidth='2px' />

        <Flex direction='column' gap='8px' textAlign='end'>
          <Text variant='bodyLargeSemiBold'>
            <FormattedMessage id='checkout.priceDetails' defaultMessage={'Price Details'} />
          </Text>
          <TicketPrice />
        </Flex>

        <Divider borderColor='dark.D100' />

        <HStack justifyContent='space-between'>
          <Text variant='bodyLargeSemiBold'>
            {new Intl.NumberFormat().format(tickets.reduce((acc, curr) => acc + curr.price, 0))} د.ع
          </Text>
          <Text variant='bodyLargeSemiBold'>
            <FormattedMessage id='checkout.total' defaultMessage={'Total'} />
          </Text>
        </HStack>

        <Button
          variant='primaryLarge'
          type='submit'
          form='ticket-holder-hook-form'
          disabled={isLoading}
        >
          {isLoading ? (
            <FormattedMessage id='processing' defaultMessage={'...جاري المعالجة'} />
          ) : (
            <FormattedMessage id='checkout.purchaseTicket' defaultMessage={'Purchase ticket'} />
          )}
        </Button>

        <Text textAlign='center' variant='caption' color='dark.D400'>
          <FormattedMessage
            id='checkout.byClickingHere'
            defaultMessage={
              'By clicking here, I state that I have read and understood the <link>Terms and conditions</link>'
            }
            values={{
              link: (chunks) => (
                <Link to={ROUTES.termsOfUse}>
                  <Text as='span' color='green.G500'>
                    {chunks}
                  </Text>
                </Link>
              ),
            }}
          />
        </Text>
      </Flex>
    </Box>
  );
};
