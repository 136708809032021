import { Flex, Image, Text, HStack, Button, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import qrSVG from 'assets/ticket-info-qr.svg';

interface SmartphoneInstructionsProps {
  onModalOpen: () => void;
}

export const SmartphoneInstructions = ({ onModalOpen }: SmartphoneInstructionsProps) => {
  return (
    <Flex
      direction='column'
      bg='white'
      p='16px'
      mt='16px'
      borderRadius='16px'
      borderColor='white'
      borderWidth='1px'
    >
      <HStack justifyContent='space-between'>
        <Image src={qrSVG} alt='qr code' boxSize='32px' />
        <VStack align='end' textAlign='end'>
          <Text variant='bodySmallSemibold'>
            <FormattedMessage
              id='checkout.youNeedPhone'
              defaultMessage={'You would need a mobile phone'}
            />
          </Text>
          <Text variant='bodySmallRegular' color='dark.D500'>
            <FormattedMessage
              id='checkout.scanAtTheGate'
              defaultMessage={'You’ll need an smartphone to scan your tickets at the gate'}
            />
          </Text>
          <Button variant='ghostNoPaddingLarge' h='16px' p='0' onClick={onModalOpen}>
            <FormattedMessage id='checkout.learnMore' defaultMessage={'Learn more'} />
          </Button>
        </VStack>
      </HStack>
    </Flex>
  );
};
