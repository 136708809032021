import { Box, ListItem, Text, VStack } from '@chakra-ui/react';
import { LocalizedList } from 'components/LocalizedList/LocalizedList';
import { FormattedMessage } from 'react-intl';

export const TermsOfUse = () => {
  return (
    <>
      <Box bg='green.G700' color='white'>
        <Box
          maxW='container.xl'
          mx='auto'
          px={{ base: '16px', md: '100px' }}
          py={{ base: '20px', md: '40px' }}
        >
          <VStack alignItems='stretch' textAlign='end' spacing='0px'>
            <VStack alignItems={'end'} m={0}>
              <Text textStyle='headlineLarge'>
                <FormattedMessage id='termsOfUse.heading' defaultMessage={'Terms of Service'} />
              </Text>
            </VStack>
          </VStack>
        </Box>
      </Box>
      <Box
        as='section'
        bg='BG100'
        py='36px'
        px={{ base: '16px', md: '100px' }}
        color='dark.D900'
        textAlign='left'
        maxW='container.xl'
        mx='auto'
      >
        <main>
          <Text mb='32px' textAlign={'right'}>
            <FormattedMessage
              id='termsOfUse.0'
              defaultMessage={
                'Welcome to Ticket Zone! These Terms of Service ("Terms") govern your use of the Ticket Zone website and services ("Services"). Please read these Terms carefully before accessing or using our Services.'
              }
            />
          </Text>
          <LocalizedList>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.1'
                defaultMessage='Acceptance of Terms: By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.2'
                defaultMessage='Ticket Purchases: Ticket Zone provides an online platform for purchasing football tickets for the Iraqi Premier Football League matches. By purchasing tickets through our Services, you agree to comply with the terms and conditions set by the league and the respective clubs.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.3'
                defaultMessage='Account Registration: In order to use certain features of our Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.4'
                defaultMessage='Ticket Availability: While we strive to provide accurate and up-to-date information regarding ticket availability, there may be instances where tickets are sold out or unavailable. Ticket purchases are subject to availability and confirmation.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.5'
                defaultMessage='Payment and Refunds: Ticket prices and payment details will be clearly specified during the checkout process. All payments are final and non-refundable, except in cases where matches are canceled or rescheduled. Refunds, if applicable, will be processed in accordance with our refund policy.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.6'
                defaultMessage='Intellectual Property: The content and materials available on our Services, including but not limited to text, graphics, logos, images, and software, are the property of Ticket Zone or its licensors and are protected by copyright and other intellectual property laws. You may not use, reproduce, modify, or distribute the content without our prior written consent.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.7'
                defaultMessage='Privacy: Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose personal information.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.8'
                defaultMessage='Limitation of Liability: To the extent permitted by law, Ticket Zone shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with your use of our Services.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.9'
                defaultMessage='Modifications: Ticket Zone reserves the right to modify or discontinue any aspect of the Services at any time without notice. We may also update these Terms from time to time. It is your responsibility to review the Terms periodically for any changes.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='termsOfUse.10'
                defaultMessage='Governing Law: These Terms shall be governed by and construed in accordance with the laws of Iraq. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Iraq.'
              />
            </ListItem>
          </LocalizedList>
        </main>
      </Box>
    </>
  );
};
