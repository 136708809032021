import { Box, Divider, Heading, VStack } from '@chakra-ui/react';
import { CartTicketInfo } from 'context/CartContext/types';
import { FormattedMessage } from 'react-intl';
import { AcceptedPayment } from './AcceptedPayment/AcceptedPayment';
import { CartEmpty } from './CartEmpty/CartEmpty';
import { PleaseNote } from './PleaseNote/PleaseNote';
import { Ticket } from './Ticket/Ticket';
import { Total } from './Total/Total';
import { useAuthCode } from 'context/AuthCodeContext/AuthCodeContext';

interface TicketCartProps {
  selectedTickets?: CartTicketInfo[];
  currency: string;
}

export const TicketCart = ({ selectedTickets, currency }: TicketCartProps) => {
  const qiToken = window.qi.user?.mobile.token;
  const authCode = useAuthCode();
  return (
    <VStack width='100%' height='100%' align='stretch' bg='white' p={0}>
      <Box width='100%'>
        <Heading p='16px' variant='headingSmall' textAlign='right'>
          <FormattedMessage id='eventpage.ticketcart.yourtickets' defaultMessage='Your tickets' />
        </Heading>
        <Divider colorScheme='BG100' />
      </Box>
      {!selectedTickets?.length ? (
        <CartEmpty />
      ) : (
        <VStack
          width='100%'
          height='100%'
          align='stretch'
          justify='space-between'
          p={0}
          overflowY='auto'
        >
          <VStack align='stretch' p='24px' spacing='24px'>
            <VStack align='stretch'>
              {selectedTickets.map((ticket, idx) => (
                <Ticket
                  key={ticket.sector + idx}
                  title={ticket.sector}
                  category={ticket.category}
                  price={ticket.price}
                  currency={currency}
                  onDeleteClick={() => ticket.deselect()}
                />
              ))}
            </VStack>
            {qiToken === undefined && authCode === null && <AcceptedPayment />}
            {qiToken === undefined && authCode === null && <PleaseNote />}
          </VStack>
          <Total
            currency={currency}
            totalAmount={selectedTickets.reduce((acc, curr) => acc + curr.price, 0)}
          />
        </VStack>
      )}
    </VStack>
  );
};
