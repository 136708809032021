import { Button } from '@chakra-ui/react';
import React from 'react';
import { toast } from 'utils/toast'; // Ensure this path is correct

interface ClipboardButtonProps {
  textToCopy: string;
  buttonText?: string;
}

const ClipboardButton: React.FC<ClipboardButtonProps> = ({ textToCopy, buttonText }) => {
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast({
        title: 'Copied!',
        description: `${text} successfully copied to clipboard.`,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Failed',
        description: `Failed to copy text. Please try again.`,
        status: 'error',
      });
    }
  };

  return (
    <Button
      bg={'yellow'}
      color={'black'}
      border={'2px solid black'}
      onClick={() => copyToClipboard(textToCopy)}
    >
      {buttonText}
    </Button>
  );
};

export default ClipboardButton;
