import { Box, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const animationKeyframes = keyframes`
  0% { transform: scale(1)  }
    25% { transform: scale(1.5) }
    50% { transform: scale(1) }
    75% { transform: scale(1.5) }
  100% { transform: scale(1) }
`;

const animation = `${animationKeyframes} 3s ease-in-out infinite`;

export const PulsatingCircle = () => {
  return (
    <Box
      as={motion.div}
      animation={animation}
      bg='green'
      width='15px'
      height='15px'
      borderRadius='100%'
      top='-5px'
      position='absolute'
      right='20px'
      border='1px solid white'
    />
  );
};
