import { Box, ListItem, Text, VStack } from '@chakra-ui/react';
import { LocalizedList } from 'components/LocalizedList/LocalizedList';
import { FormattedMessage } from 'react-intl';

export const PrivacyPolicy = () => {
  return (
    <>
      <Box bg='green.G700' color='white'>
        <Box
          maxW='container.xl'
          mx='auto'
          px={{ base: '16px', md: '100px' }}
          py={{ base: '20px', md: '40px' }}
        >
          <VStack alignItems='stretch' textAlign='end' spacing='0px'>
            <VStack alignItems={'end'} m={0}>
              <Text textStyle='headlineLarge'>
                <FormattedMessage id='privacyPolicy.heading' defaultMessage={'Privacy Policy'} />
              </Text>
            </VStack>
          </VStack>
        </Box>
      </Box>
      <Box
        as='section'
        bg='BG100'
        py='36px'
        px={{ base: '16px', md: '100px' }}
        color='dark.D900'
        textAlign='left'
        maxW='container.xl'
        mx='auto'
      >
        <main>
          <Text mb='32px' textAlign={'right'} wordBreak={'break-word'}>
            <FormattedMessage
              id='privacyPolicy.0'
              defaultMessage={
                'At Ticket Zone, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our Services. By accessing or using our Services, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.'
              }
            />
          </Text>
          <LocalizedList>
            <ListItem>
              <FormattedMessage
                id='privacyPolicy.1'
                defaultMessage='Information We Collect: We may collect personal information, such as your name, email address, phone number, and payment details, when you use our Services. We also collect non-personal information, such as your IP address and device information, for analytical and security purposes.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='privacyPolicy.2'
                defaultMessage='Use of Information: We use your personal information to facilitate ticket purchases, provide customer support, improve our Services, and communicate with you about your account and upcoming events. We may also use your information for marketing purposes, but you can opt out of receiving promotional communications.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='privacyPolicy.3'
                defaultMessage='Disclosure of Information: We may share your personal information with trusted third parties, such as payment processors and service providers, to facilitate our Services. We may also disclose your information to comply with legal obligations or protect our rights and interests.'
              />
            </ListItem>
            <ListItem>
              <FormattedMessage
                id='privacyPolicy.4'
                defaultMessage='Data Security: We take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no data transmission over the internet'
              />
            </ListItem>
          </LocalizedList>
        </main>
      </Box>
    </>
  );
};
