import { Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const CartEmpty = () => {
  return (
    <VStack height='100%' align='center' justify='center'>
      <Text variant='bodyLargeSemiBold'>
        <FormattedMessage defaultMessage='No tickets selected' id='eventpage.cartempty.notickets' />
      </Text>
      <Text color='dark.D500' variant='bodySmallRegular'>
        <FormattedMessage
          defaultMessage='Choose preferred seats on the place map'
          id='eventpage.cartempty.chooseseats'
        />
      </Text>
    </VStack>
  );
};
