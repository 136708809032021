import { getEventInfo } from 'api/eventpage';
import { useQuery } from 'react-query';

export const useConcertQuery = (eventKey?: string) => {
  if (!eventKey) {
    throw Error('No event eventKey found!');
  }

  const { isFetching, data, isError } = useQuery(
    `concert-${eventKey}`,
    async () => await getEventInfo(eventKey),
    {
      cacheTime: 1000 * 60 * 30,
    },
  );

  return {
    isFetching,
    data,
    isError,
  };
};
