import {
  AuthOtpResponse,
  AuthSignupResponse,
  RefreshTokenResponse,
  apiQiTokenResponse,
} from 'api/responseTypes';
import { tzAxios } from '../axios';
import {
  AuthOtpRequest,
  AuthSignupRequest,
  RefreshTokenRequest,
  apiQiTokenRequest,
} from 'api/requestTypes';

export const apiAuthSignup = async (payload: AuthSignupRequest) =>
  (await tzAxios.post<AuthSignupResponse>('/users/sign-up', payload)).data;

export const apiAuthOtp = async (payload: AuthOtpRequest) =>
  (await tzAxios.post<AuthOtpResponse>('/users/confirm-otp', payload)).data;

export const apiRefresh = async (payload: RefreshTokenRequest) =>
  (await tzAxios.post<RefreshTokenResponse>('/users/refresh-token', payload)).data;

export const apiQiToken = async (payload: apiQiTokenRequest) =>
  (await tzAxios.post<apiQiTokenResponse>(`/users/qi-services-ticket-zone-sign-up`, payload)).data;
