import registerQi from 'assets/register-qi-card.webp';
import seamlessBanner from 'assets/seamles-payment.webp';
import qiCardBanner from 'assets/qi-card.webp';
import { Flex, Image, Progress } from '@chakra-ui/react';
import Carousel from 'nuka-carousel';

interface HomepageCarouselProps {
  currentIdx: number;
  onChange: (currentSlideIndex: number, endSlideIndex: number) => void;
}

export const HomepageCarousel = ({ onChange, currentIdx }: HomepageCarouselProps) => {
  return (
    <Flex direction='column' justify='center' maxWidth='768px'>
      <Carousel autoplay wrapAround beforeSlide={onChange} withoutControls autoplayInterval={5000}>
        <Image alt='screen with Qi card website' src={qiCardBanner} />
        <Image alt='screen with Qi card selection' src={seamlessBanner} />
        <Image alt='screen with Qi registration form' src={registerQi} />
      </Carousel>

      <Progress
        aria-label='Banner slideshow progressbar'
        isAnimated
        value={(100 / 3) * (currentIdx + 1)}
        size='xs'
        mt='20px'
        sx={{
          '& > div:first-child': {
            transitionProperty: 'width',
            backgroundColor: 'green.G500',
          },
        }}
      />
    </Flex>
  );
};
