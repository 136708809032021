export const QI_URL = 'https://aam.qi.iq/';
export const INSTAGRAM_URL = 'https://www.instagram.com/ticketzone.app/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/digitalzoneapp/';
export const FB_URL = 'https://www.facebook.com/ticketzoneapp';

export const SUPPORT_TEL = '9647873344009';
export const SUPPORT_EMAIL = 'info@digitalzone.app';

export const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60;
export const TWENTY_THREE_MINUTES = 23 * 60;
export const THREE_MINUTES_IN_SECONDS = 180;

export const DIALING_CODE_IRAQ = '+964';

//export const INVALID_PHONE_NUMBER_REGEX = /^\d{8,10}$/;
export const INVALID_PHONE_NUMBER_REGEX = /^(?!.*\s)(7\d{9})$/;
export const INVALID_EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2}$/;
export const TWO_WORDS_MIN = /[a-zA-Z\u0600-\u06FF]+\s+[a-zA-Z\u0600-\u06FF]+/;

export const FEATURE_FLAGS_ENABLED = import.meta.env.VITE_APP_FEATURE_FLAGS === '1';

export const StatusCode = {
  PaymentSuccessful: 9000,
  TradeProcessing: 8000,
  PaymentFailed: 4000,
  UserCancelledPayment: 6001,
  NetworkException: 6002,
  UnknownPaymentResult: 6004,
};
