import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { textStyles } from './textStyles';
import { buttonStyle } from './button';
import { badgeStyle } from './badge';
import { modalStyle } from './modal';
import { defineStyleConfig } from '@chakra-ui/styled-system';

const headingTheme = defineStyleConfig({
  variants: {
    headingHero: textStyles.headlineHero,
    headingHeroSmall: textStyles.headlineHeroSmall,
    headingLarge: textStyles.headlineLarge,
    headingMedium: textStyles.headlineMedium,
    headingSmall: textStyles.headlineSmall,
  },
  baseStyle: {
    fontFamily: 'Noto Sans, Noto Sans Arabic, sans-serif',
  },
});

const textTheme = defineStyleConfig({
  variants: {
    bodyLargeRegular: textStyles.bodyLargeRegular,
    bodyLargeSemiBold: textStyles.bodyLargeSemiBold,
    bodySmallRegular: textStyles.bodySmallRegular,
    bodySmallSemibold: textStyles.bodySmallSemibold,
    bodyLargestRegular: textStyles.bodyLargestRegular,
    subtitleLarge: textStyles.subtitleLarge,
    subtitleSmallRegular: textStyles.subtitleSmallRegular,
    subtitleSmall: textStyles.subtitleSmall,
    caption: textStyles.caption,
  },
});

const badgeTheme = defineStyleConfig({
  variants: {
    primary: badgeStyle.primary,
    red: badgeStyle.red,
  },
});

const buttonTheme = defineStyleConfig({
  variants: {
    primaryLarge: buttonStyle.primaryLarge,
    primaryMedium: buttonStyle.primaryMedium,
    secondaryLarge: buttonStyle.secondaryLarge,
    secondaryMedium: buttonStyle.secondaryMedium,
    brandLarge: buttonStyle.brandLarge,
    brandMedium: buttonStyle.brandMedium,
    ghostLarge: buttonStyle.ghostLarge,
    ghostMedium: buttonStyle.ghostMedium,
    ghostNoPaddingLarge: buttonStyle.ghostNoPaddingLarge,
    ghostNoPaddingMedium: buttonStyle.ghostNoPaddingMedium,
    ghostWhiteLarge: buttonStyle.ghostWhiteLarge,
    ghostWhiteMedium: buttonStyle.ghostWhiteMedium,
    ghostWhiteNoPaddingLarge: buttonStyle.ghostWhiteNoPaddingLarge,
    ghostWhiteNoPaddingMedium: buttonStyle.ghostWhiteNoPaddingMedium,
  },
});

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        color: colors.dark.D900,
        minHeight: '100vh',
        height: '100vh',
        fontFamily: 'Noto Sans, Noto Sans Arabic, sans-serif',
      },
      body: {
        bg: colors.BG100,
        overflowX: 'hidden',
      },
    },
  },
  colors: colors,
  textStyles: textStyles,
  components: {
    Heading: headingTheme,
    Button: buttonTheme,
    Link: {
      baseStyle: {
        '&:hover': { textDecoration: 'none', color: colors.green.G0 },
      },
    },
    Badge: badgeTheme,
    CloseButton: {
      baseStyle: {
        color: 'dark.D400',
        left: '8px',
        borderRadius: '10px',
      },
    },
    Modal: {
      baseStyle: modalStyle,
    },
    Text: textTheme,
  },
});
