import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Button,
  Input,
  InputGroup,
  Flex,
  Box,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { KeyboardEvent, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSignIn } from 'react-auth-kit';
import { apiAuthOtp, apiAuthSignup } from 'api/auth';
import axios, { AxiosError } from 'axios';
import { useTimer } from 'hooks/useTimer';
import { formatTime } from 'utils/formatTime';
import { Credential } from '../ModalLogin';
import { THIRTY_DAYS_IN_SECONDS, THREE_MINUTES_IN_SECONDS } from 'utils/constants';
import { toast } from 'utils/toast';

interface OtpContentProps {
  onClose: () => void;
  onSessionRefresh: (session: string) => void;
  credential: Credential;
}

type FormData = {
  otp: number[];
};

export const OtpContent = ({ onClose, credential, onSessionRefresh }: OtpContentProps) => {
  const signIn = useSignIn();
  const { timer, resetTimer } = useTimer(THREE_MINUTES_IN_SECONDS);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { isSubmitting, errors },
    watch,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      otp: [0, 0, 0, 0, 0, 0],
    },
  });

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleInputChange = (value: number, index: number) => {
    clearErrors('otp');
    setValue(`otp.${index}`, value);

    if (value !== null && value !== undefined && index < inputRefs.current.length - 1) {
      if (inputRefs.current !== null) {
        const inputElement = inputRefs.current[index + 1] as HTMLInputElement;
        inputElement.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' && index < watch('otp').length + 1 && watch('otp')[index] === 0) {
      if (inputRefs.current !== null && index > 0) {
        const inputElement = inputRefs.current[index - 1] as HTMLInputElement;
        inputElement.focus();
      }
    }
  };

  const onSubmit = async (data: FormData) => {
    if (credential.phone === undefined || credential.session === undefined) {
      setError('otp', {
        type: 'manual',
      });
      return;
    }

    try {
      const response = await apiAuthOtp({
        otp: data.otp.join(''),
        phone: credential.phone,
        session: credential.session,
      });
      signIn({
        token: response.accessToken,
        expiresIn: response.expiresIn,
        tokenType: response.type,
        refreshToken: response.refreshToken,
        refreshTokenExpireIn: THIRTY_DAYS_IN_SECONDS,
        authState: { phone: credential.phone },
      });
      onClose();
    } catch (e) {
      const error = e as AxiosError | Error;
      console.error('Error:', error);

      if (
        axios.isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response.data.session
      ) {
        onSessionRefresh(error.response.data.session);
        setError('otp', {
          type: 'manual',
          message: error.response.data.message,
        });
      } else if (axios.isAxiosError(error) && error.response?.status === 401) {
        resetTimer(THREE_MINUTES_IN_SECONDS);
        setError('otp', {
          type: 'manual',
          message: error.response.data.message,
        });
        onClose();
      } else {
        toast({
          description: error.message,
          status: 'error',
        });
      }
    }
  };

  const onResend = async () => {
    if (credential.phone) {
      const phoneNumber = credential.phone;
      try {
        const response = await apiAuthSignup({
          phone: phoneNumber,
        });

        if (response.session) {
          onSessionRefresh(response.session);
        }
        resetTimer(THREE_MINUTES_IN_SECONDS);
        clearErrors('otp');
      } catch (e) {
        const error = e as AxiosError | Error;
        toast({
          description: error.message,
          status: 'error',
        });
      }
    }
  };

  const renderInputs = () => {
    return [Number(), Number(), Number(), Number(), Number(), Number()].map((value, index) => (
      <Controller
        defaultValue={value}
        key={index}
        name={`otp.${index}`}
        control={control}
        rules={{ required: true }}
        render={() => (
          <Input
            key={index}
            id={`otpInput.${index}`}
            type='tel'
            maxLength={1}
            ref={(el) => (inputRefs.current[index] = el)}
            onChange={(e) => handleInputChange(Number(e.target.value), index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            borderRadius='12px'
            textAlign='center'
            color='dark.D900'
            focusBorderColor='green.G500'
            borderColor={errors.otp ? 'red' : 'dark.D150'}
            width='36px'
            height='48px'
            ml='16px'
            padding={0}
            aria-invalid={Boolean(errors.otp)}
            aria-errormessage={!errors.otp ? undefined : 'optError'}
          />
        )}
      />
    ));
  };

  return (
    <Box as='section' aria-labelledby='otpModalTitle otpModalSubtitle'>
      <ModalContent>
        <ModalHeader>
          <Text id='otpModalTitle' textStyle='headlineMedium' color='dark.D900'>
            <FormattedMessage id='loginOtpModal.title' defaultMessage={'Verify your number'} />
          </Text>

          <Flex justifyContent='end' gap='5px' alignItems='center'>
            <Text mt={2} textStyle='bodySmallRegular' color='dark.D500' textAlign='left'>
              {credential.phone}
            </Text>
            <Text
              mt={2}
              id='otpModalSubtitle'
              textStyle='bodySmallRegular'
              color='dark.D500'
              textAlign='right'
            >
              <FormattedMessage
                id='loginOtpModal.subtitle'
                defaultMessage={'Enter the code sent over SMS to'}
              />
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton size={{ base: 'sm', md: 'lg' }} />

        <ModalBody justifyContent='end' textAlign='right'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup justifyContent='end'>{renderInputs()}</InputGroup>

            {errors.otp ? (
              errors.otp.type === 'manual' ? (
                <Text textStyle='bodySmallMedium' color='red' mt={2} id='phoneNumberError'>
                  {errors.otp.message}
                </Text>
              ) : (
                <Text textStyle='bodySmallMedium' color='red' mt={2} id='phoneNumberError'>
                  <FormattedMessage
                    id='loginOtpModal.error'
                    defaultMessage={'Please enter valid otp code'}
                  />
                </Text>
              )
            ) : null}

            <Flex justifyContent='end' gap='5px' mt={{ base: 6, md: 14 }} alignItems='center'>
              {timer > 0 ? (
                <Text textStyle='bodySmallMedium' color='green.G500' mb={1}>
                  {formatTime(timer)}
                </Text>
              ) : (
                <Button
                  px='0px'
                  h='0px'
                  variant='ghostNoPaddingMedium'
                  onClick={onResend}
                  aria-label='Resend the otp code now'
                >
                  <Text textStyle='bodySmallMedium' color='green.G500' mb={1}>
                    <FormattedMessage
                      id='loginOtpModal.resendButton'
                      defaultMessage={'Resend now'}
                    />
                  </Text>
                </Button>
              )}

              <Text textStyle='bodySmallMedium' color='dark.D900' mb={1}>
                <FormattedMessage
                  id='loginOtpModal.resend'
                  defaultMessage={'Didn’t receive the code? Resend in'}
                />
              </Text>
            </Flex>

            <Button
              mt={{ base: 3, md: 8 }}
              minW='100%'
              variant='primaryLarge'
              type='submit'
              isLoading={isSubmitting}
              aria-label='Submit the otp code'
            >
              <FormattedMessage id='loginOtpModal.button' defaultMessage={'Continue'} />
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Box>
  );
};
