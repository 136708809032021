import { Flex, Image, Text, HStack, Button, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import qiCard from 'assets/checkout-qiCard.webp';

interface QiCardInstructionsProps {
  onModalOpen: () => void;
}

export const QiCardInstructions = ({ onModalOpen }: QiCardInstructionsProps) => {
  return (
    <Flex
      direction='column'
      bg='green.G0'
      p='16px 6px 0 16px'
      mt='16px'
      borderRadius='16px'
      borderColor='green.G50'
      borderWidth='1px'
      width='100%'
      align='end'
    >
      <HStack spacing='20px'>
        <VStack align='end' textAlign='end'>
          <Text variant='bodySmallSemibold'>
            <FormattedMessage
              id='checkout.openQi'
              defaultMessage={'Open Qi card and get free ticket to the match'}
            />
          </Text>
          <Button variant='ghostNoPaddingLarge' h='16px' p='0' onClick={onModalOpen}>
            <FormattedMessage id='checkout.learnMore' defaultMessage={'Learn more'} />
          </Button>
        </VStack>
        <Image src={qiCard} alt='qr code' />
      </HStack>
    </Flex>
  );
};
