import { Icon, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as checkMark } from 'assets/check-mark.svg';

export const QrCodeSuccess = () => {
  return (
    <>
      <Icon as={checkMark} boxSize='66px' color='green.G500' />
      <Text textStyle='headlineSmall' color='green.G500' px='50px'>
        <FormattedMessage
          id='ticketGenerator.ticketSuccessfullyUsed'
          defaultMessage={'Ticket successfully used to enter the stadium'}
        />
      </Text>
    </>
  );
};
