import { VStack, HStack, Text, Divider, Badge } from '@chakra-ui/react';
import { OrderTicket } from 'api/responseTypes';
// import checkoutTicket from 'assets/checkout-ticket.webp';
import { FormattedMessage } from 'react-intl';

type TicketHolderProps = {
  tickets: OrderTicket[];
};

export const TicketHolder = ({ tickets }: TicketHolderProps) => {
  return (
    <VStack bg='white' gap='24px' borderRadius='16px' p='24px' align='stretch'>
      {tickets.map((ticket, index) => (
        <div key={index}>
          <HStack spacing='24px' align='start'>
            <VStack align='stretch' width='100%' textAlign='end'>
              <Text textStyle='subtitleLarge'>
                <FormattedMessage id='orderSummary.ticket' defaultMessage={'Ticket'} /> {index + 1}
              </Text>
              <HStack justifyContent='space-between'>
                <Badge
                  variant='primary'
                  textStyle='bodySmallSemibold'
                  alignItems='center'
                  px={2}
                  textTransform='capitalize'
                >
                  {tickets[index].sector}
                </Badge>
                <Text>
                  <FormattedMessage id='orderSummary.sector' defaultMessage={'Sector'} />
                </Text>
              </HStack>
              <HStack justifyContent='space-between'>
                <Text>{ticket.holderName}</Text>
                <Text>
                  <FormattedMessage
                    id='orderSummary.holderName'
                    defaultMessage={'Ticket holder name:'}
                  />
                </Text>
              </HStack>
              <HStack justifyContent='space-between'>
                <Text>{ticket.holderPhone}</Text>
                <Text>
                  <FormattedMessage
                    id='orderSummary.phoneNumber'
                    defaultMessage={'Phone number:'}
                  />
                </Text>
              </HStack>
            </VStack>

            {/* <Center display={{ base: 'none', md: 'flex' }} h='150px'>
              <Divider orientation='vertical' />
            </Center> */}

            {/* <Image
              src={checkoutTicket}
              alt='representation of the ticket'
              display={{ base: 'none', md: 'flex' }}
            /> */}
          </HStack>
          <Divider borderColor='dark.D100' _last={{ display: 'none' }} />
        </div>
      ))}
    </VStack>
  );
};
