import { Spinner, Flex } from '@chakra-ui/react';

export const LoaderOverlay = () => {
  return (
    <Flex
      p={0}
      width='100vw'
      height='100vh'
      position='fixed'
      left='0'
      top='0'
      zIndex='99'
      backdropFilter='blur(2px)'
      backgroundColor='rgba(0, 0, 0, 0.6)'
      alignItems='center'
      justifyContent='center'
    >
      <Spinner color='green.G50' size='xl' thickness='4px' />
    </Flex>
  );
};
