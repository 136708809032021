import { postCheckout } from 'api/checkoutpage';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import axios, { AxiosError } from 'axios';

export const useCheckoutMutation = () => {
  const { mutate, status, isError } = useMutation(postCheckout, {
    onSuccess: (data) => {
      window.location.replace(data.paymentLink);
    },

    onError: (e: AxiosError | Error) => {
      console.error('Error:', e);
      if (axios.isAxiosError(e)) {
        toast({
          description: e.response?.data?.message || e.message,
          status: 'error',
        });
      }
    },
  });

  return {
    mutate,
    isError,
    status,
  };
};
