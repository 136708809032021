import { Flex, Box, Image } from '@chakra-ui/react';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';
import { DesktopNavbar } from './DesktopNavbar/DesktopNavbar';
import logo from '../../assets/logo-new.svg';
import { useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';

export const Navbar = () => {
  const [isMoblile] = useMediaQuery('(min-width: 767px)');
  return (
    <Box as='nav' bg='green.G900' color='green.G0' py={4} px={4} width='100vw'>
      <Flex maxW='container.xl' mx='auto' justify='end' justifyContent='space-between'>
        <Box>{isMoblile ? <DesktopNavbar /> : <MobileNavbar />}</Box>
        <Link to={ROUTES.home} aria-label='Go to homepage'>
          <Image
            src={logo}
            w={{ base: '125px', md: '120px' }}
            h={{ base: '32px', md: '62px' }}
            alt='ticket zone logo'
          />
        </Link>
      </Flex>
    </Box>
  );
};
