import { QrCodeLoading } from './QrCodeLoading/QrCodeLoading';
import { QrCodeValid } from './QrCodeValid/QrCodeValid';

interface QrCodeProps {
  loading?: boolean;
  idToEncode: string;
}

export const QrCode = ({ loading, idToEncode }: QrCodeProps) => {
  if (loading) {
    return <QrCodeLoading />;
  }

  return <QrCodeValid idToEncode={idToEncode} />;
};
