// AUTH

export interface AuthSignupResponse {
  session: string;
}

export interface AuthOtpResponse {
  accessToken: string;
  refreshToken: string;
  type: string;
  expiresIn: number;
  isCompleted: boolean;
}

export interface RefreshTokenResponse {
  accessToken: string;
  type: string;
  expiresIn: number;
  isCompleted: boolean;
}

// HOMEPAGE

export interface TeamInfo {
  id: number;
  name: string;
  logo: string;
}

export enum MatchState {
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export interface MatchInfo {
  id: number;
  city: string;
  title: string;
  subtitle: string;
  date: string;
  time: string;
  stadium: string;
  teamA: TeamInfo;
  teamB: TeamInfo;
  startingPrice: number;
  currency: string;
  eventKey: string;
  eventId: number;
  state: MatchState;
}

export interface ConcertInfo {
  id: number;
  city: string;
  title: string;
  subtitle: string;
  date: string;
  time: string;
  startingPrice: number;
  currency: string;
  eventId: number;
  eventKey: string;
  state: MatchState;
  imageLink: string;
  address: string;
}

export interface HomeResponse {
  items: MatchInfo[];
}

export interface ConcertsResponse {
  items: ConcertInfo[];
}

// EVENT

export interface MatchPricing {
  label: string;
  category: string;
  price: number;
}

export interface ConcertPricing {
  label: string;
  category: string;
  price: number;
}

export interface MatchResponse {
  match: MatchInfo;
  pricing: MatchPricing[];
}

export interface ConcertResponse {
  concert: ConcertInfo;
  pricing: ConcertPricing[];
}

export interface CheckoutResponse {
  message: string;
  createdId: number;
  paymentLink: string;
}

export enum QrCodeStatus {
  NotYetAvailable = 'will be available 6 hours before the match',
  Available = 'available',
  NotAvailable = 'not available',
}

export interface TicketResponse {
  id: string;
  sector: string;
  gate: string;
  used: boolean;
  event: Event;
  holderName: string;
  holderPhone: string;
  qrCodeStatus: QrCodeStatus;
  qrCode: string;
  concert: ConcertInfo;
  match: MatchInfo;
}

export interface OrderTicket {
  id: number;
  sector: string;
  price: number;
  holderName: string;
  holderPhone: string;
}

export interface OrderSummaryResponse {
  id: number;
  orderNumber: number;
  totalPrice: number;
  status: string;
  transactionId: number;
  currency: string;
  user: {
    phone: number;
  };
  tickets: OrderTicket[];
  item: MatchInfo;
}

// PROFILE

export interface ProfileDetailsResponse {
  phone: string;
  first_name: string;
  last_name: string;
  gender: 'Male' | 'Female';
  birthdate: string;
  interests: string[];
  email: string;
}

// HISTORY

export type HistoryResponse = HistoryElement[];

export interface HistoryElement {
  orderNumber: string;
  totalPrice: number;
  item: any;
  id: number;
  event: Event;
}

export enum EventType {
  SPORT,
  CONCERT,
}
export interface Event {
  id: number;
  eventType: EventType;
}

export interface Concert {
  id: number;
  city: string;
  title: string;
  subtitle: string;
  date: string;
  time: string;
  startingPrice: number;
  currency: string;
  eventId: number;
  eventKey: string;
  state: MatchState;
  imageLink: string;
  address: string;
}

export interface Match {
  title: string;
  subtitle: string;
  date: string;
  time: string;
  stadium: string;
  eventKey: string;
  startingPrice: number;
  currency: string;
  city: string;
  state: string;
  teamA: TeamA;
  teamB: TeamB;
  id: number;
}

export interface TeamA {
  name: string;
  logo: string;
  id: number;
}

export interface TeamB {
  name: string;
  logo: string;
  id: number;
}

export interface apiQiTokenResponse {
  accessToken: string;
  refreshToken: string;
  type: string;
  expiresIn: number;
  isCompleted: boolean;
}

export type SingleOrderResponse = {
  eventId: number | undefined;
  eventInfoId: number;
  holdToken: string;
  tickets: {
    sector: string;
    categoryKey: string;
    price: number;
    gate: string;
    holderName: string;
    holderPhone: string;
  }[];

  paymentMethod: string;
  variantId?: number;
  deliveryMethod?: {
    type: string;
    value: string | undefined;
  };
};

export type orderNumber = {
  order_number?: string | undefined;
  order_id?: string | undefined;
};
export type OrderStatus = {
  status: string;
};
