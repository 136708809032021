import { Box, Heading, Text, VStack, Button, Grid, GridItem } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { HomepageCarousel } from './HomepageCarousel/HomepageCarousel';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { QI_URL } from 'utils/constants';

export const OpenAccount = () => {
  const [activeSlideIdx, setActiveSlideIdx] = useState<number>(0);

  const handleSlideChange = (_: number, newId: number) => {
    setActiveSlideIdx(newId);
  };

  return (
    <Box
      bg='white'
      as='section'
      px={{ base: '16px', md: '100px' }}
      py='36px'
      color='dark.D900'
      textAlign='right'
      aria-labelledby='openAccTitle'
      width='100vw'
    >
      <Box maxW='container.xl' mx='auto'>
        <Heading
          mb='24px'
          textAlign={{ base: 'center', md: 'right' }}
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
          id='openAccTitle'
        >
          <FormattedMessage
            defaultMessage='How to open Qi’s account & get the card'
            id='homepage.openAcc.heading'
          />
        </Heading>
        <Text as='h3' textAlign={{ base: 'center', md: 'right' }} color='dark.D500'>
          <FormattedMessage
            defaultMessage='Experience streamline payment, cashless transaction with only 3 steps'
            id='homepage.openAcc.subHeading'
          />
        </Text>
        <Grid
          templateColumns={{ base: '1fr', xl: 'minmax(375px, 390px) minmax(auto, 100%)' }}
          templateAreas={{ base: '"carousel" "tiles"', xl: '"tiles carousel"' }}
          mt='40px'
          w='100%'
          gap='46px'
        >
          <GridItem area='tiles'>
            <VStack align={{ base: 'center', md: 'end' }} spacing='48px'>
              <Box
                bg={activeSlideIdx === 0 ? 'BG100' : undefined}
                borderRadius='20px'
                py='16px'
                px='32px'
                width='100%'
                tabIndex={0}
                aria-labelledby='tileTitle1 tile1'
              >
                <Box display='inline-flex' py='9px' px='15px' borderRadius='8px' bg='green.G500'>
                  <Text id='tile1' variant='subtitleLarge' color='white'>
                    1
                  </Text>
                </Box>
                <Text
                  id='tileTitle1'
                  as='h4'
                  my='16px'
                  color='green.G500'
                  variant='bodyLargestRegular'
                >
                  <FormattedMessage
                    defaultMessage='Open <color>Qi</color> account'
                    id='homepage.openAcc.firstStep'
                    values={{
                      color: (chunks) => (
                        <Text as='span' color='yellow.Y500'>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
                <Text color='dark.D900' variant='subtitleSmallRegular'>
                  <FormattedMessage
                    defaultMessage='Visit Qi Website. Select Iraqi Football Association from drop down list'
                    id='homepage.openAcc.firstStepDescription'
                  />
                </Text>
              </Box>
              <Box
                bg={activeSlideIdx === 1 ? 'BG100' : undefined}
                borderRadius='20px'
                py='16px'
                px='32px'
                width='100%'
                tabIndex={0}
                aria-labelledby='tileTitle2 tile2'
              >
                <Box display='inline-flex' py='9px' px='15px' borderRadius='8px' bg='green.G500'>
                  <Text id='tile2' variant='subtitleLarge' color='white'>
                    2
                  </Text>
                </Box>
                <Text
                  id='tileTitle2'
                  as='h4'
                  my='16px'
                  color='green.G500'
                  variant='bodyLargestRegular'
                >
                  <FormattedMessage
                    defaultMessage='Register <color>Qi</color> card'
                    id='homepage.openAcc.secondStep'
                    values={{
                      color: (chunks) => (
                        <Text as='span' color='yellow.Y500'>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
                <Text color='dark.D900' variant='subtitleSmallRegular'>
                  <FormattedMessage
                    defaultMessage='Select Iraqi Football Association from drop down list, then choose the image of your favorite club.'
                    id='homepage.openAcc.secondStepDescription'
                  />
                </Text>
              </Box>
              <Box
                bg={activeSlideIdx === 2 ? 'BG100' : undefined}
                borderRadius='20px'
                py='16px'
                px='32px'
                width='100%'
                tabIndex={0}
                aria-labelledby='tileTitle3 tile3'
              >
                <Box display='inline-flex' py='9px' px='15px' borderRadius='8px' bg='green.G500'>
                  <Text id='tile3' variant='subtitleLarge' color='white'>
                    3
                  </Text>
                </Box>
                <Text
                  id='tileTitle3'
                  as='h4'
                  my='16px'
                  color='green.G500'
                  variant='bodyLargestRegular'
                >
                  <FormattedMessage
                    defaultMessage='Experience <color>seamless</color> payment'
                    id='homepage.openAcc.thirdStep'
                    values={{
                      color: (chunks) => (
                        <Text as='span' color='yellow.Y500'>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
                <Text color='dark.D900' variant='subtitleSmallRegular'>
                  <FormattedMessage
                    defaultMessage='Fill your information and be ready to receive the card at your doorstep.'
                    id='homepage.openAcc.thirdStepDescription'
                  />
                </Text>
              </Box>

              <Box px='32px'>
                <Link
                  aria-label='Open Qi account link'
                  to={QI_URL}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Button aria-label='Open Qi account button' variant='primaryLarge'>
                    <FormattedMessage
                      defaultMessage='Open Qi account free'
                      id='homepage.openAcc.button'
                    />
                  </Button>
                </Link>
              </Box>
            </VStack>
          </GridItem>
          <GridItem
            area='carousel'
            justifySelf={{ base: 'center', xl: 'end' }}
            alignSelf={{ base: 'center', xl: 'start' }}
          >
            <HomepageCarousel currentIdx={activeSlideIdx} onChange={handleSlideChange} />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
