import {
  Text,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Box,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type SponsotImageProps = {
  content: string;
  header: string;
  defaultHeaderMessage: string;
  defaultContentMessage: string;
  src: string;
};

const SponsorImage = ({
  content,
  header,
  src,
  defaultHeaderMessage,
  defaultContentMessage,
}: SponsotImageProps) => {
  return (
    <Box maxW='144px' h='64px'>
      <Popover trigger='hover'>
        <PopoverTrigger>
          <Image
            sx={{
              filter: 'grayscale(100%)',
            }}
            _hover={{ filter: 'grayscale(0%)' }}
            h={{
              base: '64px',
            }}
            alt='sponsor'
            src={src}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader
            sx={{
              borderBottomWidth: '0px',
            }}
          >
            <Text variant='subtitleLarge'>
              <FormattedMessage id={header} defaultMessage={defaultHeaderMessage} />
            </Text>
          </PopoverHeader>
          <PopoverBody>
            <Text variant='bodySmallRegular'>
              <FormattedMessage id={content} defaultMessage={defaultContentMessage} />
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default SponsorImage;
