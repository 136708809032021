import { Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const LoadingView = () => (
  <VStack bg='green.G700' textAlign='center' justifyContent='center' gap='16px' p='40px 16px 62px'>
    <Spinner size='xl' color='green.G50' thickness='4px' speed='0.7s' />
    <div>
      <Heading
        as='h1'
        variant={{ base: 'headingMedium', md: 'headingLarge' }}
        mb='4px'
        color='white'
      >
        <FormattedMessage id='checkout.loadingTitle' defaultMessage='Preparing your ticket' />
      </Heading>
      <Text textStyle='bodyLargeRegular' color='white'>
        <FormattedMessage
          id='checkout.loadingDescription'
          defaultMessage='Your ticket will be ready soon, please wait'
        />
      </Text>
    </div>
  </VStack>
);
