import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { ConcertInfo } from 'api/responseTypes';
import { ReactComponent as MapPinSVG } from 'assets/map-pin-bright.svg';
import { ReactComponent as PlaceholderTeamLogo } from 'assets/placeholder.svg';

type ConcertInfoProps = Omit<ConcertInfo, 'eventKey'>;

export const GeneralConcertInfo = ({ date, time, imageLink, address, city }: ConcertInfoProps) => (
  <Box
    bg='green.G700'
    color='white'
    h={{ base: '142px', md: '112px' }}
    textAlign={{ base: 'center' }}
  >
    <HStack justify='center' gap={{ base: '27px', md: '40px' }} height='100%'>
      <Flex justifyContent='center' alignItems='center' flexDirection='column' gap={1}>
        <VStack dir='rtl'>
          <Text textStyle='bodySmallSemibold'>{date}</Text>
          <Text textStyle='bodySmallSemibold'>{time}</Text>
        </VStack>
        <HStack>
          <Flex direction={{ base: 'column-reverse', md: 'row' }} gap='4px'>
            <Text id='gameCardTitle' textStyle='bodySmallMedium'>
              {city}
            </Text>
            <Text textStyle='bodySmallMedium' display={{ base: 'none', md: 'flex' }}>
              ・
            </Text>
            <Flex
              gap={1}
              alignItems='center'
              direction={{ base: 'row-reverse', md: 'row' }}
              color='#A0F9D9'
              mr='10px'
            >
              <Text textStyle='bodySmallRegular'>{address}</Text>
              <MapPinSVG />
            </Flex>
          </Flex>
        </HStack>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '6px', md: '16px' }}
        alignItems='center'
      >
        <Image
          src={imageLink}
          boxSize='100px'
          borderRadius='5%'
          height='auto'
          width='100px'
          fallback={<PlaceholderTeamLogo />}
        />
      </Flex>
    </HStack>
  </Box>
);
