import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { MatchInfo } from 'api/responseTypes';
import { ReactComponent as MapPinSVG } from 'assets/map-pin-bright.svg';
import { ReactComponent as PlaceholderTeamLogo } from 'assets/placeholder.svg';

type GameInfoProps = Omit<MatchInfo, 'eventKey'>;

export const GameInfo = ({ date, time, stadium, teamA, teamB, city }: GameInfoProps) => (
  <Box
    bg='green.G700'
    color='white'
    h={{ base: '142px', md: '112px' }}
    textAlign={{ base: 'center' }}
    width='100vw'
    mx='auto'
    px={{ base: '12px', md: '24px' }}
    py={{ base: '32px', md: '40px' }}
  >
    <HStack justify='center' gap={{ base: '27px', md: '40px' }} height='100%'>
      <Flex
        direction={{ base: 'column-reverse', md: 'row' }}
        gap={{ base: '6px', md: '16px' }}
        alignItems='center'
      >
        <Text id='team1' textStyle='headingSmall'>
          {teamA.name}
        </Text>
        <Image
          src={teamA.logo}
          alt={`${teamA.name} logo`}
          boxSize='64px'
          borderRadius='50%'
          height='64px'
          width='64px'
          fallback={<PlaceholderTeamLogo />}
        />
      </Flex>

      <Flex justifyContent='center' alignItems='center' flexDirection='column' gap={1}>
        <VStack dir='rtl'>
          <Text textStyle='bodySmallSemibold'>{date}</Text>
          <Text textStyle='bodySmallSemibold'>{time}</Text>
        </VStack>
        <HStack>
          <Flex direction={{ base: 'column-reverse', md: 'row' }} gap='4px'>
            <Text id='gameCardTitle' textStyle='bodySmallMedium'>
              {city}
            </Text>
            <Text textStyle='bodySmallMedium' display={{ base: 'none', md: 'flex' }}>
              ・
            </Text>
            <Flex
              gap={0}
              alignItems='center'
              justifyContent='center'
              direction={{ base: 'row-reverse', md: 'row' }}
              color='#A0F9D9'
            >
              <Text textStyle='bodySmallRegular' px={{ base: '0px', md: '4px' }}>
                {stadium}
              </Text>
              <MapPinSVG />
            </Flex>
          </Flex>
        </HStack>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '6px', md: '16px' }}
        alignItems='center'
      >
        <Image
          src={teamB.logo}
          alt={`${teamB.name} logo`}
          boxSize='64px'
          borderRadius='50%'
          height='64px'
          width='64px'
          fallback={<PlaceholderTeamLogo />}
        />
        <Text id='team1' textStyle='headingSmall'>
          {teamB.name}
        </Text>
      </Flex>
    </HStack>
  </Box>
);
