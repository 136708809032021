import { createContext } from 'react';
import { WithChildren } from 'types/component';
import { useContext } from 'react';
import { useTimer } from 'hooks/useTimer';

interface HoldTokenSessionTimerContextProps {
  resetTimer: (seconds: number) => void;
  holdTokenSessionTimer: number;
}

export const HoldTokenSessionTimerContext = createContext<
  HoldTokenSessionTimerContextProps | undefined
>(undefined);

export const HoldTokenSessionTimerProvider = ({ children }: WithChildren) => {
  const { timer: holdTokenSessionTimer, resetTimer } = useTimer(0);

  return (
    <HoldTokenSessionTimerContext.Provider value={{ holdTokenSessionTimer, resetTimer }}>
      {children}
    </HoldTokenSessionTimerContext.Provider>
  );
};

export const useHoldTokenSessionTimerContext = () => {
  const context = useContext(HoldTokenSessionTimerContext);

  if (context === undefined) {
    throw new Error('HoldTokenSessionTimerContext is undefined');
  }

  return context;
};
