import { Hero } from './Hero/Hero';
import { OpenAccount } from './OpenAccount/OpenAccount';
import { TicketHowTo } from './TicketHowTo/TicketHowTo';
import { Upcoming } from './Upcoming/Upcoming';
import { Legends } from './Legends/Legends';
import { Box } from '@chakra-ui/react';
import { useAuthCode } from 'context/AuthCodeContext/AuthCodeContext';
Legends;

export const HomePage = () => {
  const { authCode, userToken } = useAuthCode();

  return (
    <Box as='div'>
      <Hero />
      <Upcoming />
      {userToken === undefined && authCode === null && (
        <Box>
          <TicketHowTo />
          <OpenAccount />
        </Box>
      )}
      <Legends />
    </Box>
  );
};
