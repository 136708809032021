import { Container, Grid } from '@chakra-ui/react';
import { Footer } from 'components/Footer/Footer';
import { Navbar } from 'components/Navbar/Navbar';
import { WithChildren } from 'types/component';
import { useMediaQuery } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

interface PageLayoutProps extends WithChildren {
  withFooter?: boolean;
}

export const PageLayout = ({ withFooter, children }: PageLayoutProps) => {
  const [isMobile] = useMediaQuery('(max-width: 700px)');
  return (
    <Grid templateRows='auto 1fr auto' minHeight='100vh'>
      <Helmet>
        {isMobile && (
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, shrink-to-fit=no user-scalable=no'
          />
        )}
      </Helmet>

      <Navbar />
      <Container as='main' p={0} maxW='100%' maxH={withFooter ? '100%' : '100vh'}>
        {children}
      </Container>
      {withFooter && <Footer />}
    </Grid>
  );
};
