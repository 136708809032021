import { HStack, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { OrderTicket } from 'api/responseTypes';

interface TicketPriceProps {
  tickets: OrderTicket[];
  currency: string;
}

export const TicketPrice = ({ tickets, currency }: TicketPriceProps) => {
  const countTicketByPrice = tickets.reduce((countMap, ticket) => {
    countMap[ticket.price] = (countMap[ticket.price] || 0) + 1;
    return countMap;
  }, {} as Record<number, number>);

  return (
    <>
      {tickets.map((ticket, index) => {
        const numberOfTickets = countTicketByPrice[ticket.price];

        if (numberOfTickets === 1) {
          return (
            <HStack justify='space-between' mt='2px' key={index}>
              <Text variant='bodySmallRegular'>
                {currency} {new Intl.NumberFormat().format(ticket.price)}
              </Text>
              <Text variant='bodySmallRegular'>
                <FormattedMessage id='checkout.ticketPrice' defaultMessage={'Ticket price'} />
              </Text>
            </HStack>
          );
        }

        if (index === tickets.findIndex((t) => t.price === ticket.price)) {
          return (
            <HStack justify='space-between' mt='2px' key={index}>
              <Text variant='bodySmallRegular'>
                {numberOfTickets} x {currency} {new Intl.NumberFormat().format(ticket.price)}
              </Text>
              <Text variant='bodySmallRegular'>
                <FormattedMessage id='checkout.ticketPrice' defaultMessage={'Ticket price'} />
              </Text>
            </HStack>
          );
        }
        return null;
      })}
    </>
  );
};
