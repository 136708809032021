import { Modal, ModalOverlay, ModalContent, useDisclosure } from '@chakra-ui/react';
import { ReservationExpiredContent } from './ReservationExpiredContent/ReservationExpiredContent';

interface ModalReservationExpiredProps {
  isOpen: boolean;
}

export const ModalReservationExpired = ({ isOpen }: ModalReservationExpiredProps) => {
  const { onClose } = useDisclosure();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent textAlign='right' minW={{ base: '100%', md: '440px' }}>
        <ReservationExpiredContent />
      </ModalContent>
    </Modal>
  );
};
