import { Button, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'utils/toast';
import axios from 'axios';
import { useSignIn } from 'react-auth-kit';
import useHylideBridge from 'hooks/useHylideBridge';

interface LoginInfoCartProps {
  onModalOpen: () => void;
}

export const LoginInformation = ({ onModalOpen }: LoginInfoCartProps) => {
  const hylideBridge = useHylideBridge();
  interface sucessProps {
    authCode: string;
  }
  const signIn = useSignIn();

  const baseApi = import.meta.env.VITE_APP_BASE_API;

  const handleAlipayAuthentication = async () => {
    try {
      if (hylideBridge) {
        hylideBridge.getAuthCode({
          scopes: ['USER_CONTACTINFO', 'auth_user'],
          success: async (res: sucessProps) => {
            const authCode = res.authCode;

            try {
              await axios
                .post(`${baseApi}/users/mini-program-sign-up`, {
                  auth_code: authCode,
                })
                .then((res) => {
                  const accessToken: string = res.data.accessToken;

                  if (accessToken) {
                    signIn({
                      token: res.data.accessToken,
                      expiresIn: res.data.expiresIn,
                      tokenType: res.data.type,
                      refreshToken: res.data.refreshToken,
                      refreshTokenExpireIn: res.data.expiresIn,
                      authState: { phone: '', session: '', name: '', email: '', gender: '' },
                    });

                    toast({
                      status: 'success',
                      description: `Successfully signed in!`,
                    });
                  } else {
                    toast({
                      status: 'error',
                      description: 'Access token not found in the response.',
                    });
                  }
                });
            } catch (error) {
              toast({
                status: 'error',
                description: `Error in POST: ${error}`,
              });
            }
          },
          fail: (error) => {
            onModalOpen();
            console.log('error', error);
          },
        });
      } else {
        toast({
          status: 'error',
          description: `hylideBridge is null.`,
        });
      }
    } catch (error) {
      toast({
        status: 'error',
        description: `Error in superApp! ${error}`,
      });
    }
  };

  console.log('onModalOpen', onModalOpen);

  return (
    <VStack width='100%' height='100%' align='stretch' bg='white' p={0}>
      <VStack height='100%' align='center' justify='center'>
        <Text variant='bodyLargeSemiBold'>
          <FormattedMessage
            defaultMessage='No tickets selected'
            id='eventpage.loginInfo.notickets'
          />
        </Text>
        <Text color='dark.D500' variant='bodySmallRegular' pb='16px'>
          <FormattedMessage
            id='eventpage.loginInfo.messege'
            defaultMessage='Please login in order to buy tickets'
          />
        </Text>
        <Button variant='primaryMedium' onClick={handleAlipayAuthentication}>
          <FormattedMessage id='eventpage.loginInfo.loginButton' defaultMessage='Log in' />
        </Button>
      </VStack>
    </VStack>
  );
};
