/* eslint-disable @typescript-eslint/ban-ts-comment */
import { apiRefresh } from 'api/auth/index';
import { createRefresh } from 'react-auth-kit';
import { THIRTY_DAYS_IN_SECONDS, TWENTY_THREE_MINUTES } from '../../utils/constants';

export const refreshApi = createRefresh({
  interval: TWENTY_THREE_MINUTES,
  //@ts-ignore
  refreshApiCallback: async ({ refreshToken }) => {
    try {
      const response = await apiRefresh({ refreshToken });
      return {
        isSuccess: true,
        newAuthToken: response.accessToken,
        newAuthTokenExpireIn: response.expiresIn,
        newRefreshToken: refreshToken,
        newRefreshTokenExpiresIn: THIRTY_DAYS_IN_SECONDS,
      };
    } catch (error) {
      return {
        isSuccess: false,
      };
    }
  },
});
