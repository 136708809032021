import { Image, Text, HStack, VStack, Divider, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import ticketSVG from 'assets/instructions-ticket.svg';
import qrSVG from 'assets/instructions-QR.svg';
import scanSVG from 'assets/instructions-scan.svg';

export const SmartphoneInstructionsContent = () => {
  return (
    <Flex flexDirection='column' px={{ base: '16px', md: '32px' }} py='40px' align='end' gap='24px'>
      <Text
        variant='bodyLargestRegular'
        pb={{ base: '20px', md: '24px' }}
        textAlign={{ base: 'center', md: 'end' }}
      >
        <FormattedMessage
          id='checkout.modal.smartphone.ticketInstruction'
          defaultMessage={'Smartphone ticket instruction'}
        />
      </Text>
      <HStack spacing={{ base: '20px', md: '36px' }} pr={{ base: '0px', md: '12px' }}>
        <VStack align='end' spacing='16px'>
          <Text variant='bodyLargestRegular' color='green.G500'>
            <FormattedMessage
              id='checkout.modal.smartphone.purchaseYourTicket'
              defaultMessage={'Purchase Your Ticket'}
            />
          </Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage
              id='checkout.modal.smartphone.purchaseYourTicketDescription'
              defaultMessage={
                'First, purchase your ticket for the event or experience you want to attend on our website.'
              }
            />
          </Text>
        </VStack>
        <Image src={ticketSVG} alt='icon representing ticket' />
      </HStack>

      <Divider />

      <HStack spacing={{ base: '20px', md: '36px' }} pr={{ base: '0px', md: '12px' }}>
        <VStack align='end' spacing='16px'>
          <Text variant='bodyLargestRegular' color='green.G500'>
            <FormattedMessage
              id='checkout.modal.smartphone.receiveYourQRCode'
              defaultMessage={'Receive Your QR Code'}
            />
          </Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage
              id='checkout.modal.smartphone.receiveYourQRCodeDescription'
              defaultMessage={
                'Once your payment is processed, you will receive a confirmation SMS with your ticket. QR code will be available few hours before the match.'
              }
            />
          </Text>
        </VStack>
        <Image src={qrSVG} alt='icon representing qr code' />
      </HStack>

      <Divider />

      <HStack spacing={{ base: '20px', md: '36px' }} pr={{ base: '0px', md: '12px' }} pb='16px'>
        <VStack align='end' spacing='16px'>
          <Text variant='bodyLargestRegular' color='green.G500'>
            <FormattedMessage
              id='checkout.modal.smartphone.presentYourQRCode'
              defaultMessage={'Present Your QR Code'}
            />
          </Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage
              id='checkout.modal.smartphone.presentYourQRCodeDescription'
              defaultMessage={
                'To ensure smooth entrance take a screenshot of your QR code as soon as it is available. At the event or experience, simply present your QR code at the entrance for scanning to gain access.'
              }
            />
          </Text>
        </VStack>
        <Image src={scanSVG} alt='icon representing scanning' />
      </HStack>
    </Flex>
  );
};
